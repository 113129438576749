import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Header from '../Header';
import EmptyStateNotFound from '../EmptyStateNotFound';
import Tabs from '../Tabs';
import Logs from './Logs';
import FeedbackList from '../FeedbackList';
import PlanList from '../PlanList';
import Profile from './Profile';
import { AppState } from '../../state/reducers';
import { selectors as usersSelectors } from '../../state/ducks/users';
import { selectors as feedbackSelectors } from '../../state/ducks/feedback';
import { selectors as logsSelectors } from '../../state/ducks/logs';
import { selectors as plansSelectors } from '../../state/ducks/plans';
import iconArrowBack from '../../assets/arrow-back.svg';
import iconAdd from '../../assets/add.svg';

type Params = {
  userId: string;
};

const User = () => {
  const { userId } = useParams<Params>();

  const user = useSelector((state: AppState) => usersSelectors.getById(state.users, userId));
  const logs = useSelector((state: AppState) => logsSelectors.getByUserId(state.logs, userId));
  const plans = useSelector((state: AppState) => plansSelectors.getByUserId(state.plans, userId));
  const feedback = useSelector((state: AppState) => (
    feedbackSelectors.getByUserId(state.feedback, userId)
  ));

  let content = <EmptyStateNotFound />;

  if (!isEmpty(user)) {
    const tabs = [
      {
        name: 'logs',
        title: 'Logboek',
        content: <Logs logs={logs} name={user.name} userId={userId} />,
      },
      {
        name: 'plan',
        title: 'Plan',
        content: <PlanList plans={plans} name={user.name} userId={userId} />,
      },
      {
        name: 'feedback',
        title: 'Feedback',
        content: <FeedbackList feedback={feedback} name={user.name} userId={userId} />,
      },
      {
        name: 'profile',
        title: 'Profiel',
        content: <Profile user={user} />,
      },
    ];

    content = <Tabs tabs={tabs} />;
  }

  return (
    <section className="page user">
      <Header
        leftButton={(
          <Link to="/">
            <img src={iconArrowBack} alt="back" className="icon" />
          </Link>
        )}
        rightButton={(
          <Link to={`/user/${user.userId}/feedback`}>
            <img src={iconAdd} alt="add" className="icon" />
          </Link>
        )}
        text={user.name}
      />
      {content}
    </section>
  );
};

export default User;
