import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import '../styles/ListItem.css';

type Props = {
  primaryText: ReactNode;
  secondaryText: ReactNode;
  avatarText?: ReactNode;
  avatarImageUrl?: string;
  active?: boolean;
  to?: string;
  isLastItem?: boolean;
  isLastSection?: boolean;
  dividerOffset?: number;
};

const propTypes = {
  primaryText: PropTypes.node.isRequired,
  secondaryText: PropTypes.node.isRequired,
  avatarText: PropTypes.node,
  avatarImageUrl: PropTypes.string,
  active: PropTypes.bool,
  to: PropTypes.string,
  isLastItem: PropTypes.bool,
  isLastSection: PropTypes.bool,
  dividerOffset: PropTypes.number,
};

const ListItem = (props: Props) => {
  const {
    primaryText,
    secondaryText,
    avatarText = null,
    avatarImageUrl = '',
    active = false,
    to,
    isLastItem = false,
    isLastSection = false,
    dividerOffset = 0,
  } = props;

  const dividerStyle = {
    width: `calc(100% - ${dividerOffset}px)`,
    margin: `0 0 0 ${dividerOffset}px`,
  };

  const hasAvatar = avatarText || avatarImageUrl;

  let avatar = null;

  if (avatarText) {
    avatar = (
      <div className={classNames('avatar', { contrast: active })}>
        <p className="button-contrast">{avatarText}</p>
      </div>
    );
  }

  if (avatarImageUrl) {
    avatar = <img src={avatarImageUrl} alt="user" className="avatar" />;
  }

  const children = (
    <>
      <section>
        {avatar}
        <section className={classNames('text-container', { 'has-avatar': hasAvatar })}>
          <h1 className="heading">{primaryText}</h1>
          <p className="body-light">{secondaryText}</p>
        </section>
      </section>
      <hr
        className={classNames(
          'divider',
          {
            hidden: active || (isLastItem && !isLastSection),
          },
        )}
        style={dividerStyle}
      />
    </>
  );

  if (to) {
    return (
      <NavLink
        to={to}
        className={classNames('list-item', { active })}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <section className={classNames('list-item', { active })}>
      {children}
    </section>
  );
};

ListItem.propTypes = propTypes;

export default ListItem;
