import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import size from 'lodash/size';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import nl2br from 'react-nl2br';
import { useSelector } from 'react-redux';

import EmptyStateFeedback from './EmptyStateFeedback';
import formatDate from '../utils/formatDate';
import '../styles/Feedback.css';
import { FeedbackType } from '../types';
import { AppState } from '../state/reducers';
import { selectors as logsSelectors } from '../state/ducks/logs';
import iconChevronRight from '../assets/chevron-right.svg';

const propTypes = {
  feedback: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string,
};

type Props = {
  feedback: FeedbackType[];
  name?: string;
  userId?: string;
};

type ItemProps = {
  isLastItem: boolean;
  feedback: FeedbackType;
  userId?: string;
};

const FeedbackListItem = (props: ItemProps) => {
  const { isLastItem, feedback, userId = '' } = props;

  const log = useSelector((state: AppState) => logsSelectors.getById(state.logs, feedback.logId || ''));

  let header = <h1 className="heading">{formatDate(new Date(feedback.createdAt), 'EEEE dd LLLL')}</h1>;

  if (!isEmpty(log)) {
    header = (
      <>
        <header>
          <h1 className="heading">Feedback voor {formatDate(new Date(log.date), 'EEEE dd LLLL')}</h1>
          <Link
            to={
              userId
                ? `/user/${userId}/log/${log.logId}`
                : `/log/${log.logId}`
            }
            className="button-accent"
          >
            Bekijk verslag <img src={iconChevronRight} alt="right" />
          </Link>
        </header>
        <p className="body-light">Gegeven op {formatDate(new Date(feedback.createdAt), 'EEEE dd LLLL')}</p>
      </>
    );
  }

  return (
    <section className="feedback-item">
      <section>
        {header}
        <p className="body-light">{nl2br(feedback.description)}</p>
      </section>
      <hr
        className={classNames('divider', { hidden: isLastItem })}
      />
    </section>
  );
};

const FeedbackList = (props: Props) => {
  const { feedback, name = '', userId = '' } = props;

  if (isEmpty(feedback)) {
    return <EmptyStateFeedback name={name} />;
  }

  return (
    <section className="list feedback">
      {map(feedback, (item, index) => {
        const isLastItem = (index + 1) === size(feedback);

        return (
          <FeedbackListItem
            isLastItem={isLastItem}
            feedback={item}
            key={item.feedbackId}
            userId={userId}
          />
        );
      })}
    </section>
  );
};

FeedbackList.propTypes = propTypes;

export default FeedbackList;
