import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { UserState, UserActionTypes } from './types';

/* State Shape
{
  userId: 'userId',
  ...
}
*/

const initialState: UserState = {};

const reducer: Reducer<
UserState,
UserActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return {
      ...state,
      ...action.payload,
    };
    case actionTypes.CLEAR: return {};
    default: return state;
  }
};

export default reducer;
