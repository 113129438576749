import React, { useState, useEffect, FormEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { useAuth0 } from '@auth0/auth0-react';
import Textarea from 'react-textarea-autosize';

import { selectors as userSelectors } from '../state/ducks/user';
import Header from './Header';
import Button from './Button';
import DrawerButton from './DrawerButton';
import getInitials from '../utils/getInitials';
import { user as userSchema } from '../utils/schemas';
import { usersUpdate } from '../state/operations/user';
import { AppState } from '../state/reducers';
import '../styles/About.css';

type FormErrors = {
  about?: string;
};

const About = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => userSelectors.get(state.user));

  const [errors, setErrors] = useState<FormErrors>({});
  const [about, setAbout] = useState(user.about || '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isEmpty(user)) {
      setAbout(user.about || '');
    }
  }, [user]);

  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = async (data: { about: string }) => {
    setLoading(true);

    const token = await getAccessTokenSilently();

    dispatch(usersUpdate(token, data, () => setLoading(false)));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setErrors({});

    try {
      userSchema.validateSync({
        about,
      }, {
        abortEarly: false,
        stripUnknown: true,
      });
    } catch (error) {
      const validationErrors = reduce(
        error.inner,
        (result, err) => ({ ...result, [err.path]: err.message }),
        {},
      );

      return setErrors(validationErrors);
    }

    return onSubmit({
      about,
    });
  };

  return (
    <section className="page about">
      <Header
        leftButton={<DrawerButton />}
      />
      <section className="content">
        <section>
          <header>
            {
              user.picture
                ? (
                  <img src={user.picture} alt="user" className="avatar large" />
                )
                : (
                  <div className="avatar large contrast">
                    <p className="button-accent">{getInitials(user.name || '')}</p>
                  </div>
                )
            }
            <section>
              <h1 className="heading">{user.name}</h1>
              <p className="body-light">{user.email}</p>
            </section>
          </header>
          <h1 className="heading">Over mij</h1>
          <Textarea
            className="input"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
          {
            errors.about
            && (
              <p className="body-error">{errors.about}</p>
            )
          }
        </section>
        <Button labelText="Bewaar" onClick={handleSubmit} disabled={loading} />
      </section>
    </section>
  );
};

export default About;
