import React, { useState, useEffect, FormEvent } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import { useSelector } from 'react-redux';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import parseInt from 'lodash/parseInt';
import nlLocale from 'date-fns/locale/nl';
import Textarea from 'react-textarea-autosize';

import { PlanType, PlanSubmitType } from '../types';
import Button from './Button';
import { plan as planSchema } from '../utils/schemas';
import durationOptions from '../utils/durationOptions';
import formatDate from '../utils/formatDate';
import getEndDate from '../utils/getEndDate';
import toDate from '../utils/toDate';
import { AppState } from '../state/reducers';
import { selectors as mediaSelectors } from '../state/ducks/media';
import '../styles/LogForm.css';

const propTypes = {
  plan: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

type Errors = {
  startDate?: string;
  exercises?: string;
  frequency?: string;
  attention?: string;
};

type Props = {
  plan?: PlanType;
  onSubmit: (plan: PlanSubmitType) => void;
  loading?: boolean;
};

const PlanForm = (props: Props) => {
  const { plan = {}, onSubmit, loading = false } = props;

  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  const [errors, setErrors] = useState<Errors>({});
  const [startDate, setStartDate] = useState(toDate(get(plan, 'startDate')));
  const [duration, setDuration] = useState('2');
  const [exercises, setExercises] = useState(get(plan, 'exercises') || '');
  const [frequency, setFrequency] = useState(get(plan, 'frequency') || '');
  const [attention, setAttention] = useState(get(plan, 'attention') || '');

  useEffect(
    () => {
      if (!isEmpty(plan)) {
        setStartDate(toDate(get(plan, 'startDate')));
        setExercises(get(plan, 'exercises') || '');
        setFrequency(get(plan, 'frequency') || '');
        setAttention(get(plan, 'attention') || '');
      }
    },
    [plan],
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setErrors({});

    try {
      planSchema.validateSync({
        startDate,
        frequency,
        attention,
        exercises,
      }, {
        abortEarly: false,
        stripUnknown: true,
      });
    } catch (error) {
      const validationErrors = reduce(
        error.inner,
        (result, err) => ({ ...result, [err.path]: err.message }),
        {},
      );

      return setErrors(validationErrors);
    }

    return onSubmit({
      startDate,
      endDate: getEndDate(startDate, parseInt(duration)),
      frequency,
      attention,
      exercises,
    });
  };

  const handleStartDateChange = (value: Date) => {
    if (!value) {
      return false;
    }

    return setStartDate(value);
  };

  return (
    <form className="content plan-form" onSubmit={handleSubmit}>
      <section>
        <header>
          {
            !isEmpty(plan)
              ? <h1 className="heading-large">{`${formatDate(new Date(get(plan, 'startDate')), 'dd LLLL')} - ${formatDate(new Date(get(plan, 'endDate')), 'dd LLLL')}`}</h1>
              : <h1 className="heading-large">Nieuw bewegingsplan</h1>
          }
          {
            medium
              ? <Button type="text" labelText="Bewaar" onClick={handleSubmit} disabled={loading} />
              : null
          }
        </header>
        <section className="container">
          <section className="row">
            <div>
              <h2 className="heading">Start datum</h2>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat="dd-MM-yyyy"
                className="date-input"
                locale={nlLocale}
                startDate={startDate}
                endDate={getEndDate(startDate, parseInt(duration))}
              />
              {
                errors.startDate
                && (
                  <p className="body-error">{errors.startDate}</p>
                )
              }
            </div>
            <div>
              <h2 className="heading">Looptijd</h2>
              <Dropdown
                options={durationOptions}
                onChange={(o) => setDuration(o.value)}
                value={duration}
              />
            </div>
          </section>
          <h2 className="heading">Oefeningen</h2>
          <Textarea
            className="input"
            minRows={2}
            value={exercises}
            onChange={(event) => setExercises(event.currentTarget.value)}
          />
          {
            errors.exercises
            && (
              <p className="body-error">{errors.exercises}</p>
            )
          }
          <h2 className="heading">Frequentie</h2>
          <Textarea
            className="input"
            minRows={2}
            value={frequency}
            onChange={(event) => setFrequency(event.currentTarget.value)}
          />
          {
            errors.frequency
            && (
              <p className="body-error">{errors.frequency}</p>
            )
          }
          <h2 className="heading">Aandachtspunten</h2>
          <Textarea
            className="input"
            minRows={2}
            value={attention}
            onChange={(event) => setAttention(event.currentTarget.value)}
          />
          {
            errors.attention
            && (
              <p className="body-error">{errors.attention}</p>
            )
          }
        </section>
      </section>
      <Button labelText="Bewaar" onClick={handleSubmit} disabled={loading} />
    </form>
  );
};

PlanForm.propTypes = propTypes;

export default PlanForm;
