import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import logo from '../assets/logo.svg';
import '../styles/Header.css';

type Props = {
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  text?: ReactNode;
};

const propTypes = {
  leftButton: PropTypes.node,
  rightButton: PropTypes.node,
  text: PropTypes.node,
};

const Header = (props: Props) => {
  const {
    leftButton = null,
    rightButton = null,
    text = null,
  } = props;

  return (
    <header className="app-header">
      <div className="header-button">{leftButton}</div>
      {
        text
          ? <h1 className="heading-large">{text}</h1>
          : <img src={logo} alt="BB Coachen" />
      }
      <div className="header-button">{rightButton}</div>
    </header>
  );
};

Header.propTypes = propTypes;

export default Header;
