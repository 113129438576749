import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useAuth0 } from '@auth0/auth0-react';

import { PlanSubmitType } from '../types';
import Header from './Header';
import PlanForm from './PlanForm';
import EmptyStateNotFound from './EmptyStateNotFound';
import { AppState } from '../state/reducers';
import { selectors as plansSelectors } from '../state/ducks/plans';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { plansUpdate } from '../state/operations/plans';
import iconArrowBack from '../assets/arrow-back.svg';

const Plan = () => {
  const { planId } = useParams();

  const plan = useSelector((state: AppState) => plansSelectors.getById(state.plans, planId));

  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (update: PlanSubmitType) => {
    setLoading(true);

    const token = await getAccessTokenSilently();

    dispatch(plansUpdate(token, { ...update, planId }, () => setLoading(false)));
  };

  if (medium) {
    return isEmpty(plan)
      ? <EmptyStateNotFound />
      : <PlanForm plan={plan} onSubmit={onSubmit} loading={loading} />;
  }

  return (
    <section className="page plan">
      <Header
        leftButton={(
          <Link to="/plan">
            <img src={iconArrowBack} alt="back" className="icon" />
          </Link>
        )}
      />
      {
        isEmpty(plan)
          ? <EmptyStateNotFound />
          : <PlanForm plan={plan} onSubmit={onSubmit} loading={loading} />
      }
    </section>
  );
};

export default Plan;
