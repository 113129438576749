import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import useInterval from '@use-it/interval';

import Logs from '../components/Logs';
import Feedback from '../components/Feedback';
import AddLog from '../components/AddLog';
import Log from '../components/Log';
import Plans from '../components/Plans';
import Plan from '../components/Plan';
import AddPlan from '../components/AddPlan';
import About from '../components/About';
import Drawer from '../components/Drawer';
import { logsList } from '../state/operations/logs';
import { plansList } from '../state/operations/plans';
import { feedbackList } from '../state/operations/feedback';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { actions as uiActions, selectors as uiSelectors } from '../state/ducks/ui';
import { interval } from '../utils/constants';

const UserRouter = () => {
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const drawerOpen = useSelector((state) => uiSelectors.drawerOpen(state.ui));

  const toggleDrawer = () => dispatch(uiActions.toggleDrawer());

  const medium = useSelector((state) => mediaSelectors.getQuery(state.media, 'medium'));

  useEffect(
    () => {
      const getDataAsync = async () => {
        const token = await getAccessTokenSilently();

        dispatch(feedbackList(token));
        dispatch(logsList(token));
        dispatch(plansList(token));
      };

      getDataAsync();
    },
    [],
  );

  useInterval(
    () => {
      const getDataAsync = async () => {
        const token = await getAccessTokenSilently();

        dispatch(feedbackList(token));
        dispatch(logsList(token));
        dispatch(plansList(token));
      };

      getDataAsync();
    },
    interval,
  );

  if (medium) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/plan" component={Plans} />
          <Route path="/about-me" component={About} />
          <Route path="/feedback" component={Feedback} />
          <Route path="/" component={Logs} />
        </Switch>
        <Drawer open={drawerOpen} closeDrawer={toggleDrawer} />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/add-log" component={AddLog} />
        <Route path="/log/:logId" component={Log} />
        <Route path="/plan/:planId" component={Plan} />
        <Route path="/plan" component={Plans} />
        <Route path="/add-plan" component={AddPlan} />
        <Route path="/about-me" component={About} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/" component={Logs} />
      </Switch>
      <Drawer open={drawerOpen} closeDrawer={toggleDrawer} />
    </BrowserRouter>
  );
};

export default UserRouter;
