import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';

import './styles/meyer-reset.css';
import './styles/index.css';
import './styles/Text.css';
import './styles/Page.css';
import './styles/EmptyState.css';
import * as serviceWorker from './serviceWorker';
import config from './auth0-config.json';
import App from './App';
import { store, persistor } from './state/store';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      redirectUri={window.location.origin}
      audience={config.audience}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://create-react-app.dev/docs/making-a-progressive-web-app/
serviceWorker.register();
