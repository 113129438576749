import split from 'lodash/split';
import flatMap from 'lodash/flatMap';
import take from 'lodash/take';
import toUpper from 'lodash/toUpper';
import replace from 'lodash/replace';
import size from 'lodash/size';
import first from 'lodash/first';
import last from 'lodash/last';
import isNil from 'lodash/isNil';

const getInitials = (name: string, amount = 2) => {
  const words = split(name, ' ');

  const initials = flatMap(
    words,
    (word) => take(split(replace(word, /[^a-zA-Z]/g, ''), ''), 1),
  );

  const firstLetter = first(initials);
  const lastLetter = last(initials);

  if (isNil(firstLetter)) {
    return '#';
  }

  if (amount !== 2 || size(initials) === 1) {
    return toUpper(firstLetter);
  }

  return toUpper(`${firstLetter}${lastLetter}`);
};

export default getInitials;
