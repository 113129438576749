import { AxiosError } from 'axios';

// https://github.com/mzabriskie/axios#handling-errors
const logAxiosError = (error: AxiosError) => {
  if (process.env.NODE_ENV !== 'production') {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const response = error && error.response;
      console.error('Error:', response.data, response.status, response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error('Error:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error:', error.message);
    }
  }
};

export default logAxiosError;
