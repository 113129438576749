import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import differenceBy from 'lodash/differenceBy';
import map from 'lodash/map';
import { Dispatch } from 'redux';

import { actions as logsActions, selectors as logsSelectors } from '../ducks/logs';
import logAxiosError from '../../utils/logAxiosError';
import { LogType, LogSubmitType } from '../../types';
import { AppState } from '../reducers';

const logsListResult = (dispatch: Dispatch, result: AxiosResponse, getState: () => AppState) => {
  const { ok, logs } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  if (isEmpty(logs)) {
    return dispatch(logsActions.clear());
  }

  const existingLogs = logsSelectors.getAll(getState().logs);

  const logsToRemove = map(differenceBy(existingLogs, logs, 'logId'), 'logId');

  dispatch(logsActions.remove(logsToRemove));
  return dispatch(logsActions.add(logs));
};

const logsList = (token: string) => (
  (dispatch: Dispatch, getState: () => AppState) => (
    axios({
      method: 'get',
      url: '/api/v1/logs/list',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => logsListResult(dispatch, result, getState),
      logAxiosError,
    )
  )
);

const logsUpdateResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  done: (log: LogType) => void,
) => {
  const { ok, log } = get(result, 'data', {});

  if (!ok) {
    return done(log);
  }

  dispatch(logsActions.update([log]));

  return done(log);
};

const logsUpdate = (token: string, log: LogSubmitType, done = noop) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/logs/update',
      data: log,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => logsUpdateResult(dispatch, result, done),
      logAxiosError,
    )
  )
);

const logsCreateResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  done: (log: LogType | { error: string }) => void,
) => {
  const { ok, log, error } = get(result, 'data', {});

  if (!ok) {
    return done({ error });
  }

  dispatch(logsActions.add([log]));

  return done(log);
};

const logsCreate = (token: string, log: LogSubmitType, done = noop) => (
  (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();

    const existingLog = logsSelectors.getByDate(state.logs, log.date) as LogType;

    if (!isEmpty(existingLog)) {
      const update = {
        logId: existingLog.logId,
        date: existingLog.date,
        activities: `${existingLog.activities} ${log.activities}`,
        grade: log.grade,
        description: log.description ? `${existingLog.description} ${log.description}` : existingLog.description,
      };

      // I currently have no idea to fix this error. Argument of type '(dispatch: Dispatch) => Promise<false | void>' is not assignable to parameter of type 'AnyAction'.
      // @ts-ignore
      return dispatch(logsUpdate(token, update, done));
    }

    return axios({
      method: 'post',
      url: '/api/v1/logs/create',
      data: log,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => logsCreateResult(dispatch, result, done),
      logAxiosError,
    );
  }
);

const logsDestroyResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  logId: string,
  done: () => void,
) => {
  const { ok } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  dispatch(logsActions.remove(logId));

  return done();
};

const logsDestroy = (token: string, logId: string, done = noop) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/logs/destroy',
      data: {
        logId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => logsDestroyResult(dispatch, result, logId, done),
      logAxiosError,
    )
  )
);

export {
  logsList,
  logsCreate,
  logsUpdate,
  logsDestroy,
};
