import React, { useState, useEffect, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import nlLocale from 'date-fns/locale/nl';
import Textarea from 'react-textarea-autosize';

import NumericInput from './NumericInput';
import Button from './Button';
import formatDate from '../utils/formatDate';
import toDate from '../utils/toDate';
import { AppState } from '../state/reducers';
import { selectors as logsSelectors } from '../state/ducks/logs';
import { log as logSchema } from '../utils/schemas';
import { selectors as mediaSelectors } from '../state/ducks/media';
import iconChevronLeft from '../assets/chevron-left.svg';
import iconChevronRight from '../assets/chevron-right.svg';
import '../styles/LogForm.css';
import { LogType, LogSubmitType } from '../types';

const propTypes = {
  log: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

type Errors = {
  date?: string;
  grade?: string;
  activities?: string;
  description?: string;
  gratitude?: string;
};

type Props = {
  log?: LogType;
  onSubmit: (log: LogSubmitType) => void;
  loading?: boolean;
};

const LogForm = (props: Props) => {
  const { log = {}, onSubmit, loading = false } = props;

  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  const latestLog = useSelector((state: AppState) => logsSelectors.getLatest(state.logs));
  const logs = useSelector((state: AppState) => logsSelectors.getSorted(state.logs));

  const [errors, setErrors] = useState<Errors>({});
  const [date, setDate] = useState(toDate(get(log, 'date')));
  const [grade, setGrade] = useState(get(log, 'grade') || latestLog.grade || 6);
  const [activities, setActivities] = useState(get(log, 'activities') || '');
  const [description, setDescription] = useState(get(log, 'description') || '');
  const [pulse, setPulse] = useState(get(log, 'pulse') || '');
  const [gratitude, setGratitude] = useState(get(log, 'gratitude') || '');

  useEffect(
    () => {
      if (!isEmpty(log)) {
        setDate(toDate(get(log, 'date')));
        setGrade(get(log, 'grade') || latestLog.grade || 6);
        setActivities(get(log, 'activities') || '');
        setDescription(get(log, 'description') || '');
        setPulse(get(log, 'pulse') || '');
        setGratitude(get(log, 'gratitude') || '');
      }
    },
    [log],
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setErrors({});

    const data = {
      date,
      grade,
      description,
      gratitude,
      pulse,
      activities,
    };

    try {
      logSchema.validateSync(data, {
        abortEarly: false,
        stripUnknown: true,
      });
    } catch (error) {
      const validationErrors = reduce(
        error.inner,
        (result, err) => ({ ...result, [err.path]: err.message }),
        {},
      );

      return setErrors(validationErrors);
    }

    return onSubmit(data);
  };

  const handleDateChange = (value: Date | null) => {
    if (!value) {
      return false;
    }

    return setDate(value);
  };

  let nav = null;

  if (!isEmpty(log) && !medium) {
    const logIndex = findIndex(logs, { logId: get(log, 'logId') });

    const nextLog = logs[logIndex + 1];
    const previousLog = logs[logIndex - 1];

    nav = (
      <nav className="prev-next">
        {
          !isEmpty(nextLog)
            ? (
              <Link to={`/log/${nextLog.logId}`} className="button-accent">
                <img src={iconChevronLeft} alt="left" />
                Vorige
              </Link>
            )
            : <span />
        }
        {
          !isEmpty(previousLog)
            ? (
              <Link to={`/log/${previousLog.logId}`} className="button-accent">
                Volgende
                <img src={iconChevronRight} alt="right" />
              </Link>
            )
            : <span />
        }
      </nav>
    );
  }

  return (
    <form className="content log-form" onSubmit={handleSubmit}>
      <section>
        {nav}
        <header>
          <h1 className="heading-large">
            {
              !isEmpty(log)
                ? formatDate(date, 'EEEE dd LLLL yyyy')
                : 'Nieuw verslag'
            }
          </h1>
          {
            medium
              ? <Button type="text" labelText="Bewaar" onClick={handleSubmit} disabled={loading} />
              : null
          }
        </header>
        <section className="container">
          <section className="row">
            <div>
              <h2 className="heading">Datum</h2>
              <DatePicker
                selected={date}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                className="date-input"
                locale={nlLocale}
              />
              {
                errors.date
                && (
                  <p className="body-error">{errors.date}</p>
                )
              }
            </div>
            <div>
              <NumericInput
                value={grade}
                onChange={setGrade}
                labelText="Gevoel van de dag"
                minValue={1}
                maxValue={10}
              />
              {
                errors.grade
                && (
                  <p className="body-error">{errors.grade}</p>
                )
              }
            </div>
            <div>
              <h2 className="heading">Ochtendpols</h2>
              <input
                className="input"
                maxLength={3}
                value={pulse}
                onChange={(event) => setPulse(event.currentTarget.value)}
              />
            </div>
          </section>
          <h2 className="heading">Wat heb ik gedaan vandaag</h2>
          <Textarea
            className="input"
            value={activities}
            onChange={(event) => setActivities(event.currentTarget.value)}
          />
          {
            errors.activities
            && (
              <p className="body-error">{errors.activities}</p>
            )
          }
          <h2 className="heading">Beschrijf het gevoel van de dag</h2>
          <Textarea
            className="input"
            minRows={2}
            value={description}
            onChange={(event) => setDescription(event.currentTarget.value)}
          />
          {
            errors.description
            && (
              <p className="body-error">{errors.description}</p>
            )
          }
          <h2 className="heading">Ik wil nog delen dat</h2>
          <Textarea
            className="input"
            minRows={2}
            value={gratitude}
            onChange={(event) => setGratitude(event.currentTarget.value)}
          />
          {
            errors.gratitude
            && (
              <p className="body-error">{errors.gratitude}</p>
            )
          }
        </section>
      </section>
      <Button labelText="Bewaar" onClick={handleSubmit} disabled={loading} />
    </form>
  );
};

LogForm.propTypes = propTypes;

export default LogForm;
