import * as yup from 'yup';

const description = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value));

const gratitude = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value));

const pulse = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value));

const about = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value));

const feedback = yup
  .string()
  .trim()
  .required('Feedback is verplicht.');

const date = yup
  .date()
  .required('Datum is verplicht.');

const startDate = yup
  .date()
  .required('Start datum is verplicht.');

const grade = yup
  .number()
  .required('Cijfer is verplicht.');

const activities = yup
  .string()
  .required('Activiteiten zijn verplicht');

const exercises = yup
  .string()
  .required('Oefeningen zijn verplicht');

const frequency = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value));

const attention = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value));

const log = yup
  .object()
  .shape({
    description,
    gratitude,
    date,
    grade,
    activities,
    pulse,
  });

const plan = yup
  .object()
  .shape({
    startDate,
    exercises,
    frequency,
    attention,
  });

const user = yup
  .object()
  .shape({
    about,
  });

const feedbackSchema = yup
  .object()
  .shape({
    feedback,
  });

export {
  log,
  plan,
  user,
  feedbackSchema,
};
