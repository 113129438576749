import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import size from 'lodash/size';
import map from 'lodash/map';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import Dropdown from 'react-dropdown';

import ListItem from '../ListItem';
import { AppState } from '../../state/reducers';
import { selectors as usersSelectors } from '../../state/ducks/users';
import getInitials from '../../utils/getInitials';
import formatDate from '../../utils/formatDate';

const propTypes = {
  activeUserId: PropTypes.string,
};

type Props = {
  activeUserId?: string;
};

const dropdownOptions = [
  {
    value: 'active',
    label: 'Actieve coachees',
  },
  {
    value: 'inactive',
    label: 'Inactieve coachees',
  },
  {
    value: 'all',
    label: 'Alle coachees',
  },
];

const Users = (props: Props) => {
  const {
    activeUserId = '',
  } = props;

  const [filterOption, setFilterOption] = useState('active');

  const users = useSelector((state: AppState) => usersSelectors.getAll(state.users));

  let list = users;

  if (filterOption === 'active') {
    list = reject(users, 'inactive');
  }

  if (filterOption === 'inactive') {
    list = filter(users, 'inactive');
  }

  return (
    <section className="list">
      <Dropdown
        options={dropdownOptions}
        onChange={(o) => setFilterOption(o.value)}
        value={filterOption}
      />
      {map(list, (user, index) => {
        const active = user.userId === activeUserId;
        const isLastItem = index + 1 === size(list);

        return (
          <ListItem
            primaryText={user.name}
            secondaryText={
              user.lastEntry
                ? `Laatste entry ${formatDate(new Date(user.lastEntry), 'dd LLLL')}`
                : 'Nog geen entry'
            }
            avatarText={getInitials(user.name)}
            avatarImageUrl={user.picture || ''}
            to={`/user/${user.userId}`}
            active={active}
            isLastItem={isLastItem}
            dividerOffset={56}
            key={user.userId}
          />
        );
      })}
    </section>
  );
};

Users.propTypes = propTypes;

export default Users;
