import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import getWeek from 'date-fns/getWeek';

import {
  LogsState,
  LogsAddActionType,
  LogsUpdateActionType,
  LogsRemoveActionType,
} from './types';

const add = (state: LogsState, action: LogsAddActionType): LogsState => {
  const { payload } = action;

  const logs = reduce(
    payload,
    (result, item) => (
      assignIn(
        result,
        {
          [item.logId]: assignIn(
            item,
            {
              weekNumber: getWeek(new Date(item.date)),
            },
          ),
        },
      )
    ),
    {},
  );

  return {
    ...state,
    ...logs,
  };
};

const update = (state: LogsState, action: LogsUpdateActionType): LogsState => {
  const { payload } = action;

  const logs = reduce(
    payload,
    (result, item) => (
      assignIn(
        result,
        {
          [item.logId]: assignIn(
            item,
            {
              weekNumber: getWeek(new Date(item.date)),
            },
          ),
        },
      )
    ),
    {},
  );

  return {
    ...state,
    ...logs,
  };
};

const remove = (state: LogsState, action: LogsRemoveActionType): LogsState => {
  let { payload } = action;

  if (!isArray(payload)) {
    payload = [payload];
  }

  return omit(state, payload);
};

export {
  add,
  update,
  remove,
};
