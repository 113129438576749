import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import toArray from 'lodash/toArray';
import filter from 'lodash/filter';
import size from 'lodash/size';
import find from 'lodash/find';
import isAfter from 'date-fns/isAfter';

import EMPTY_ARRAY from '../../../utils/empty-array';
import EMPTY_OBJECT from '../../../utils/empty-object';
import toDate from '../../../utils/toDate';
import { FeedbackState } from './types';
import { FeedbackType } from '../../../types';

const getAll = (state: FeedbackState): FeedbackType[] => {
  if (isEmpty(state)) {
    return EMPTY_ARRAY as unknown as FeedbackType[];
  }

  return toArray(state);
};

const getById = (state: FeedbackState, id: string): FeedbackType => (
  get(state, id) || EMPTY_OBJECT
);

const getSortedSelector = (state: FeedbackState): FeedbackType[] => orderBy(state, ['createdAt'], ['desc']);

const getSorted = createSelector(
  (state: FeedbackState) => state,
  getSortedSelector,
);

const getByUserIdSelector = (
  state: FeedbackState,
  userId: string,
): FeedbackType[] => (
  orderBy(filter(state, { userId }), ['createdAt'], ['desc'])
);

const getByUserId = createSelector(
  (state: FeedbackState) => state,
  (state: FeedbackState, userId: string) => userId,
  getByUserIdSelector,
);

const unreadSelector = (state: FeedbackState, readAt: Date): number => (
  size(filter(state, (feedback) => isAfter(toDate(feedback.createdAt), toDate(readAt))))
);

const unread = createSelector(
  (state: FeedbackState) => state,
  (state: FeedbackState, readAt: Date) => readAt,
  unreadSelector,
);

const getByLogId = (state: FeedbackState, logId: string): FeedbackType => (
  find(state, { logId }) || EMPTY_OBJECT as FeedbackType
);

export {
  getAll,
  getById,
  getSorted,
  getByUserId,
  unread,
  getByLogId,
};
