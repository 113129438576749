import React from 'react';

import users from '../../assets/users.jpg';
import getBackgroundStyles from '../../utils/getBackgroundStyles';

const EmptyStateUsers = () => (
  <section className="empty-state" style={getBackgroundStyles(users)}>
    <h1 className="heading-large-contrast">Er zijn nog geen coachees</h1>
  </section>
);

export default EmptyStateUsers;
