import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Loading from './components/Loading';
import Login from './components/Login';
import Router from './router';

const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };

    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return <Router />;
};

export default App;
