import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import toArray from 'lodash/toArray';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';

import EMPTY_ARRAY from '../../../utils/empty-array';
import EMPTY_OBJECT from '../../../utils/empty-object';
import { PlansState } from './types';
import { PlanType } from '../../../types';

const getAll = (state: PlansState): PlanType[] => {
  if (isEmpty(state)) {
    return EMPTY_ARRAY as unknown as PlanType[];
  }

  return toArray(state);
};

const getById = (state: PlansState, id: string): PlanType => (
  get(state, id) || EMPTY_OBJECT
);

const getSortedSelector = (state: PlansState): PlanType[] => orderBy(state, ['startDate'], ['desc']);

const getSorted = createSelector(
  (state: PlansState) => state,
  getSortedSelector,
);

const getByUserIdSelector = (
  state: PlansState,
  userId: string,
): PlanType[] => (
  orderBy(filter(state, { userId }), ['startDate'], ['desc'])
);

const getByUserId = createSelector(
  (state: PlansState) => state,
  (state: PlansState, userId: string) => userId,
  getByUserIdSelector,
);

export {
  getAll,
  getById,
  getByUserId,
  getSorted,
};
