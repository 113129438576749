import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string,
};

type Props = {
  fill?: string;
};

const Trending = (props: Props) => {
  const { fill = '#11171C' } = props;

  return (
    <svg height="24" viewBox="0 0 24 24" width="24" fill={fill}>
      <path id="a" d="m23 5c.0064615 0 .0129086.00006128.0193409.0001833.0233401.00045159.046654.00171168.0699092.00378311.0204448.00178155.0401703.00413457.0597111.00705293.0169878.00260874.0344141.00568283.0517646.00922073.0220203.00439973.0437107.00959356.0651138.0154809.0156237.00437676.0310117.00900775.0463029.01401712.0196552.0063844.0394367.01357765.058916.02136587.0177371.00709246.03504.01457509.052163.0225694.0206699.00970328.0410724.02012056.0610555.03120241.0121174.00662306.0243361.01373115.0364318.02111968.0255662.01572325.0504219.03249464.0744505.05031806.0391663.02899944.0765534.06118566.1119475.09657971l-.0903567-.08010726c.0634317.04976575.1206982.10703229.1704639.17046396.0055015.00690767.0110326.01414704.0164514.02144347.0178445.02417592.0346159.04903166.0502672.07464642.0074605.01204708.0145686.02426575.0213962.03659809.0108773.01976815.0212946.0401707.0310174.06096295.0079748.01700065.0154574.0343035.0224283.05176313.0079098.01975682.015103.03953834.0216788.05959961.004818.01488507.009449.03027313.0137016.04574344.0060115.02155649.0112053.04324689.0156799.06519887.003463.01741884.0065371.03484509.0091474.05232723.0072566.04810478.0110178.09784659.0110178.14846687l-.0037021-.0862308c.0015457.01796531.0026074.03596443.003185.05397618l.0005171.03225462v6c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-3.586l-7.7928932 7.7931068c-.360484.3604839-.927715.3882135-1.3200062.0831886l-.0942074-.0831886-4.2928932-4.2928932-6.79289322 6.7928932c-.39052429.3905243-1.02368927.3905243-1.41421356 0-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l7.5-7.49999998c.39052429-.39052429 1.02368927-.39052429 1.41421356 0l4.29289322 4.29289318 7.084-7.0857864h-3.584c-.5128358 0-.9355072-.38604019-.9932723-.88337887l-.0067277-.11662113c0-.55228475.4477153-1 1-1z" />
    </svg>
  );
};

Trending.propTypes = propTypes;

export default Trending;
