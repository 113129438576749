import * as actionTypes from './actionTypes';
import { MediaState } from './types';

const set = (payload: MediaState) => ({
  type: actionTypes.SET,
  payload,
});

export {
  set, // eslint-disable-line import/prefer-default-export
};
