import get from 'lodash/get';

import { MediaState } from './types';

const getAll = (state: MediaState): MediaState => state;

const getQuery = (state: MediaState, query: string): boolean => get(state, query) || false;

export {
  getAll,
  getQuery,
};
