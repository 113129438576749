import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { UIState, UIActionTypes } from './types';

/* State Shape
{
  drawerOpen: false,
  ...
}
*/

const initialState: UIState = {
  drawerOpen: false,
};

const reducer: Reducer<
UIState,
UIActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_DRAWER: return {
      ...state,
      drawerOpen: !state.drawerOpen,
    };
    default: return state;
  }
};

export default reducer;
