import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

const dev = process.env.NODE_ENV !== 'production';
const migrations = {};

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  timeout: 10000,
  stateReconciler: autoMergeLevel2,
  whitelist: ['logs', 'plans', 'user', 'feedback', 'users'],
  migrate: createMigrate(migrations, { debug: dev }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware),
  ),
);

const persistor = persistStore(store);

export {
  store,
  persistor,
};
