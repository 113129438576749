import React from 'react';
import PropTypes from 'prop-types';

import feedback from '../assets/feedback.jpg';
import getBackgroundStyles from '../utils/getBackgroundStyles';

type Props = {
  name?: string;
};

const propTypes = {
  name: PropTypes.string,
};

const EmptyStateFeedback = (props: Props) => {
  const { name = '' } = props;

  let text = 'Je hebt nog geen feedback ontvangen';

  if (name) {
    text = `Je hebt ${name} nog geen feedback gegeven`;
  }

  return (
    <section className="empty-state" style={getBackgroundStyles(feedback)}>
      <h1 className="heading-large-contrast">{text}</h1>
    </section>
  );
};

EmptyStateFeedback.propTypes = propTypes;

export default EmptyStateFeedback;
