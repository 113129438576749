import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import Header from './Header';
import LogForm from './LogForm';
import iconArrowBack from '../assets/arrow-back.svg';
import { logsCreate } from '../state/operations/logs';
import { LogSubmitType } from '../types';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { AppState } from '../state/reducers';

const AddLog = () => {
  const dispatch = useDispatch();

  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onSubmit = async (data: LogSubmitType) => {
    setLoading(true);

    const token = await getAccessTokenSilently();

    return dispatch(logsCreate(token, data, (log) => {
      setLoading(false);

      if (log.logId) {
        history.push(`/log/${log.logId}`);
      }
    }));
  };

  if (medium) {
    return <LogForm onSubmit={onSubmit} loading={loading} />;
  }

  return (
    <section className="page add-log">
      <Header
        leftButton={(
          <Link to="/">
            <img src={iconArrowBack} alt="back" className="icon" />
          </Link>
        )}
      />
      <LogForm onSubmit={onSubmit} loading={loading} />
    </section>
  );
};

export default AddLog;
