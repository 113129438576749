import { combineReducers } from 'redux';
import feedback from '../ducks/feedback';
import logs from '../ducks/logs';
import media from '../ducks/media';
import plans from '../ducks/plans';
import user from '../ducks/user';
import users from '../ducks/users';
import ui from '../ducks/ui';

const reducer = combineReducers({
  feedback,
  logs,
  media,
  plans,
  ui,
  user,
  users,
});

export type AppState = ReturnType<typeof reducer>;

export default reducer;
