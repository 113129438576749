import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string,
};

type Props = {
  fill?: string;
};

const Person = (props: Props) => {
  const { fill = '#11171C' } = props;

  return (
    <svg height="24" viewBox="0 0 24 24" width="24" fill={fill}>
      <path id="a" d="m16 14c2.7614237 0 5 2.2385763 5 5v2c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-2c0-1.6568542-1.3431458-3-3-3h-8c-1.65685425 0-3 1.3431458-3 3v2c0 .5522847-.44771525 1-1 1s-1-.4477153-1-1v-2c0-2.7614237 2.23857625-5 5-5zm-4-12c2.7614237 0 5 2.23857625 5 5s-2.2385763 5-5 5c-2.76142375 0-5-2.23857625-5-5s2.23857625-5 5-5zm0 2c-1.6568542 0-3 1.34314575-3 3s1.3431458 3 3 3 3-1.34314575 3-3-1.3431458-3-3-3z" />
    </svg>
  );
};

Person.propTypes = propTypes;

export default Person;
