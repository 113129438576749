import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { Dispatch } from 'redux';

import { actions as userActions } from '../ducks/user';
import logAxiosError from '../../utils/logAxiosError';

const usersInfoResult = (dispatch: Dispatch, result: AxiosResponse) => {
  const { ok, user } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  return dispatch(userActions.add(user));
};

const usersInfo = (token: string) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'get',
      url: '/api/v1/users/info',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => usersInfoResult(dispatch, result),
      logAxiosError,
    )
  )
);

const usersUpdateResult = (dispatch: Dispatch, result: AxiosResponse, done = noop) => {
  const { ok, user } = get(result, 'data', {});

  if (!ok) {
    return done();
  }

  dispatch(userActions.add(user));

  return done();
};

const usersUpdate = (token: string, data: { about: string }, done = noop) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/users/update',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }).then(
      (result) => usersUpdateResult(dispatch, result, done),
      logAxiosError,
    )
  )
);

const usersLastReadResult = (dispatch: Dispatch, result: AxiosResponse) => {
  const { ok, user } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  return dispatch(userActions.add(user));
};

const usersLastRead = (token: string) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/users/last-read',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => usersLastReadResult(dispatch, result),
      logAxiosError,
    )
  )
);

export {
  usersInfo,
  usersUpdate,
  usersLastRead,
};
