import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import * as utils from './utils';
import {
  LogsState,
  LogsActionTypes,
  LogsAddActionType,
  LogsUpdateActionType,
  LogsRemoveActionType,
} from './types';

/* State Shape
{
  [logId]: Log,
  ...
}
*/

const initialState: LogsState = {};

const reducer: Reducer<
LogsState,
LogsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as LogsAddActionType);
    case actionTypes.UPDATE: return utils.update(state, action as LogsUpdateActionType);
    case actionTypes.REMOVE: return utils.remove(state, action as LogsRemoveActionType);
    case actionTypes.CLEAR: return {};
    default: return state;
  }
};

export default reducer;
