import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import noop from 'lodash/noop';
import map from 'lodash/map';
import differenceBy from 'lodash/differenceBy';
import { Dispatch } from 'redux';

import { actions as feedbackActions, selectors as feedbackSelectors } from '../../ducks/feedback';
import { AppState } from '../../reducers';
import logAxiosError from '../../../utils/logAxiosError';

const adminFeedbackCreateResult = (dispatch: Dispatch, result: AxiosResponse, done: () => void) => {
  const { ok, feedback } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  dispatch(feedbackActions.add([feedback]));

  return done();
};

const adminFeedbackCreate = (
  token: string,
  data: {
    userId: string,
    description: string,
    logId?: string,
  },
  done = noop,
) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/admin/feedback/create',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }).then(
      (result) => adminFeedbackCreateResult(dispatch, result, done),
      logAxiosError,
    )
  )
);

const adminFeedbackListResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  getState: () => AppState,
) => {
  const { ok, feedback } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  const existingFeedback = feedbackSelectors.getAll(getState().feedback);

  const feedbackToRemove = map(differenceBy(existingFeedback, feedback, 'feedbackId'), 'feedbackId');

  dispatch(feedbackActions.remove(feedbackToRemove));
  return dispatch(feedbackActions.add(feedback));
};

const adminFeedbackList = (token: string) => (
  (dispatch: Dispatch, getState: () => AppState) => (
    axios({
      method: 'get',
      url: '/api/v1/admin/feedback/list',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => adminFeedbackListResult(dispatch, result, getState),
      logAxiosError,
    )
  )
);

export {
  adminFeedbackCreate,
  adminFeedbackList,
};
