import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import Header from './Header';
import FeedbackList from './FeedbackList';
import DrawerButton from './DrawerButton';
import { AppState } from '../state/reducers';
import { selectors as feedbackSelectors } from '../state/ducks/feedback';
import { usersLastRead } from '../state/operations/user';

const Feedback = () => {
  const { getAccessTokenSilently } = useAuth0();

  const feedback = useSelector((state: AppState) => feedbackSelectors.getSorted(state.feedback));

  const dispatch = useDispatch();

  useEffect(() => {
    const usersLastReadAsync = async () => {
      const token = await getAccessTokenSilently();

      dispatch(usersLastRead(token));
    };

    usersLastReadAsync();
  }, []);

  return (
    <section className="page">
      <Header
        leftButton={<DrawerButton />}
      />
      <FeedbackList feedback={feedback} />
    </section>
  );
};

export default Feedback;
