import React, { useEffect, useState, ReactNode } from 'react';
import PropTypes from 'prop-types';
import first from 'lodash/first';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import classNames from 'classnames';

import '../styles/Tabs.css';

type Tab = {
  name: string;
  title: ReactNode;
  content: ReactNode;
};

type Props = {
  tabs: Tab[];
};

const propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
  })).isRequired,
};

const Tabs = (props: Props) => {
  const { tabs } = props;

  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    const firstTab = first(tabs);

    if (firstTab) {
      setActiveTab(firstTab.name);
    }
  }, []);

  let tabContent = null;

  if (activeTab) {
    tabContent = get(find(tabs, { name: activeTab }), 'content');
  }

  return (
    <section className="tabs">
      <nav>
        {map(tabs, (tab) => {
          const active = tab.name === activeTab;

          return (
            <button
              type="button"
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={classNames({ active })}
            >
              <p className="button-accent">{tab.title}</p>
            </button>
          );
        })}
      </nav>
      {tabContent}
    </section>
  );
};

Tabs.propTypes = propTypes;

export default Tabs;
