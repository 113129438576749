import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

import Header from '../Header';
import EmptyStateNotFound from '../EmptyStateNotFound';
import Button from '../Button';
import { AppState } from '../../state/reducers';
import { selectors as usersSelectors } from '../../state/ducks/users';
import { selectors as plansSelectors } from '../../state/ducks/plans';
import iconArrowBack from '../../assets/arrow-back.svg';
import iconAdd from '../../assets/add.svg';
import iconChevronLeft from '../../assets/chevron-left.svg';
import iconChevronRight from '../../assets/chevron-right.svg';
import formatDate from '../../utils/formatDate';

const Plan = () => {
  const { planId, userId } = useParams();

  const history = useHistory();

  const plan = useSelector((state: AppState) => plansSelectors.getById(state.plans, planId));
  const plans = useSelector((state: AppState) => plansSelectors.getByUserId(state.plans, userId));
  const user = useSelector((state: AppState) => usersSelectors.getById(state.users, plan.userId));

  const planIndex = findIndex(plans, { planId });

  const nextPlan = plans[planIndex + 1];
  const previousPlan = plans[planIndex - 1];

  let content = <EmptyStateNotFound />;

  if (!isEmpty(plan)) {
    content = (
      <section className="content">
        <section>
          <nav className="prev-next">
            {
              !isEmpty(nextPlan)
                ? (
                  <Link to={`/user/${userId}/plan/${nextPlan.planId}`} className="button-accent">
                    <img src={iconChevronLeft} alt="left" />
                    Vorige
                  </Link>
                )
                : <span />
            }
            {
              !isEmpty(previousPlan)
                ? (
                  <Link to={`/user/${userId}/plan/${previousPlan.planId}`} className="button-accent">
                    Volgende
                    <img src={iconChevronRight} alt="right" />
                  </Link>
                )
                : <span />
            }
          </nav>
          <h1 className="heading-large">{`${formatDate(new Date(plan.startDate), 'EEEE dd LLLL')} - ${formatDate(new Date(plan.endDate), 'EEEE dd LLLL')}`}</h1>
          <h2 className="heading">Oefeningen</h2>
          <p className="body margin">{plan.exercises}</p>
          {
            plan.frequency
              ? (
                <>
                  <h2 className="heading">Frequentie</h2>
                  <p className="body margin">{plan.frequency}</p>
                </>
              )
              : null
          }
          {
            plan.attention
              ? (
                <>
                  <h2 className="heading">Aandachtspunten</h2>
                  <p className="body margin">{plan.attention}</p>
                </>
              )
              : null
          }
        </section>
        <Button labelText="Geef feedback" onClick={() => history.push(`/feedback/${user.userId}`)} />
      </section>
    );
  }

  return (
    <section className="page log">
      <Header
        leftButton={(
          <Link to={`/user/${userId}`}>
            <img src={iconArrowBack} alt="back" className="icon" />
          </Link>
        )}
        rightButton={(
          <Link to={`/feedback/${user.userId}`}>
            <img src={iconAdd} alt="add" className="icon" />
          </Link>
        )}
        text={user.name}
      />
      {content}
    </section>
  );
};

export default Plan;
