import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import logo from '../assets/logo.svg';
import Button from './Button';
import '../styles/Login.css';

const Loading = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <section className="login">
      <img src={logo} alt="BB Coachen" />
      <Button labelText="Log in" onClick={loginWithRedirect} />
    </section>
  );
};

export default Loading;
