import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import map from 'lodash/map';

import { LogType } from '../types';
import EmptyStateLogs from './EmptyStateLogs';
import ListItem from './ListItem';
import formatDate from '../utils/formatDate';
import '../styles/ListItem.css';

const propTypes = {
  name: PropTypes.string,
  userId: PropTypes.string,
  logs: PropTypes.arrayOf(PropTypes.object).isRequired,
  showEmptyState: PropTypes.bool,
};

type Props = {
  name?: string;
  userId?: string;
  logs: LogType[];
  showEmptyState?: boolean;
};

const Logs = (props: Props) => {
  const {
    name = '',
    userId = '',
    logs,
    showEmptyState = true,
  } = props;

  if (isEmpty(logs)) {
    return showEmptyState ? <EmptyStateLogs name={name} /> : null;
  }

  return (
    <section className="list">
      {map(logs, (log, index) => {
        const isLastItem = index + 1 === size(logs);

        return (
          <ListItem
            primaryText={
              userId && !log.readAt
                ? <span>{formatDate(new Date(log.date), 'EEEE dd LLLL')} <span className="dot" /></span>
                : formatDate(new Date(log.date), 'EEEE dd LLLL')
            }
            secondaryText={(
              <span>
                {log.pulse && <strong className="body">{log.pulse}</strong>}
                {log.pulse && ' | '}
                {log.activities}
              </span>
            )}
            avatarText={log.grade}
            to={
              userId
                ? `/user/${userId}/log/${log.logId}`
                : `/log/${log.logId}`
            }
            isLastItem={isLastItem}
            dividerOffset={56}
            key={log.logId}
          />
        );
      })}
    </section>
  );
};

Logs.propTypes = propTypes;

export default Logs;
