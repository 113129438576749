import React from 'react';

import logo from '../assets/logo.svg';
import '../styles/Loading.css';

const Loading = () => (
  <section className="loading">
    <img src={logo} alt="BB Coachen" />
    <div className="ripple">
      <div />
      <div />
    </div>
  </section>
);

export default Loading;
