import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string,
};

type Props = {
  fill?: string;
};

const Calendar = (props: Props) => {
  const { fill = '#11171C' } = props;

  return (
    <svg height="24" viewBox="0 0 24 24" width="24" fill={fill}>
      <path id="a" d="m16 1c.5522847 0 1 .44771525 1 1v1h2c1.6568542 0 3 1.34314575 3 3v14c0 1.6568542-1.3431458 3-3 3h-14c-1.65685425 0-3-1.3431458-3-3v-14c0-1.65685425 1.34314575-3 3-3h2v-1c0-.51283584.38604019-.93550716.88337887-.99327227l.11662113-.00672773c.55228475 0 1 .44771525 1 1v1h6v-1c0-.51283584.3860402-.93550716.8833789-.99327227zm4 10h-16v9c0 .5522847.44771525 1 1 1h14c.5522847 0 1-.4477153 1-1zm-13-6h-2c-.55228475 0-1 .44771525-1 1v3h16v-3c0-.55228475-.4477153-1-1-1h-2v1c0 .51283584-.3860402.93550716-.8833789.99327227l-.1166211.00672773c-.5522847 0-1-.44771525-1-1v-1h-6v1c0 .51283584-.38604019.93550716-.88337887.99327227l-.11662113.00672773c-.55228475 0-1-.44771525-1-1z" />
    </svg>
  );
};

Calendar.propTypes = propTypes;

export default Calendar;
