import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import differenceBy from 'lodash/differenceBy';
import { Dispatch } from 'redux';

import { AppState } from '../../reducers';
import { actions as logsActions, selectors as logsSelectors } from '../../ducks/logs';
import logAxiosError from '../../../utils/logAxiosError';

const adminLogsListResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  getState: () => AppState,
) => {
  const { ok, logs } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  const existingLogs = logsSelectors.getAll(getState().logs);

  const logsToRemove = map(differenceBy(existingLogs, logs, 'logId'), 'logId');

  dispatch(logsActions.remove(logsToRemove));
  return dispatch(logsActions.add(logs));
};

const adminLogsList = (token: string) => (
  (dispatch: Dispatch, getState: () => AppState) => (
    axios({
      method: 'get',
      url: '/api/v1/admin/logs/list',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => adminLogsListResult(dispatch, result, getState),
      logAxiosError,
    )
  )
);

const adminLogsReadAtResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
) => {
  const { ok, log } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  return dispatch(logsActions.update([log]));
};

const adminLogsReadAt = (token: string, logId: string) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/admin/logs/read-at',
      data: {
        logId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => adminLogsReadAtResult(dispatch, result),
      logAxiosError,
    )
  )
);

export {
  adminLogsList,
  adminLogsReadAt,
};
