import React, { MouseEvent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../styles/Button.css';

type Props = {
  labelText: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: 'filled' | 'text';
  disabled?: boolean;
};

const propTypes = {
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['text', 'filled']),
  disabled: PropTypes.bool,
};

const Button = (props: Props) => {
  const {
    labelText,
    onClick,
    type = 'filled',
    disabled = false,
  } = props;

  return (
    <button type="button" className={classNames('button', type)} onClick={onClick} disabled={disabled}>
      <p className={type === 'filled' ? 'button-contrast' : 'button-accent'}>{labelText}</p>
    </button>
  );
};

Button.propTypes = propTypes;

export default Button;
