import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';

import {
  UsersState,
  UsersAddActionType,
  UsersUpdateActionType,
  UsersRemoveActionType,
} from './types';

const add = (state: UsersState, action: UsersAddActionType): UsersState => {
  const { payload } = action;

  const feedback = reduce(
    payload,
    (result, item) => assignIn(result, { [item.userId]: item }),
    {},
  );

  return {
    ...state,
    ...feedback,
  };
};

const update = (state: UsersState, action: UsersUpdateActionType): UsersState => {
  const { payload } = action;

  const feedback = reduce(
    payload,
    (result, item) => assignIn(result, { [item.userId]: item }),
    {},
  );

  return {
    ...state,
    ...feedback,
  };
};

const remove = (state: UsersState, action: UsersRemoveActionType): UsersState => {
  let { payload } = action;

  if (!isArray(payload)) {
    payload = [payload];
  }

  return omit(state, payload);
};

export {
  add,
  update,
  remove,
};
