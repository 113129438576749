import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string,
};

type Props = {
  fill?: string;
};

const Inbox = (props: Props) => {
  const { fill = '#11171C' } = props;

  return (
    <svg height="24" viewBox="0 0 24 24" width="24" fill={fill}>
      <path id="a" d="m16.6993168 2.9999993c1.1876646-.00081049 2.263996.699099 2.744543 1.7839708l3.47 7.81.0084036.0187966c.0121054.0287966.0229015.0582792.0323137.0883733l-.0407173-.1071699c.0217156.0488759.0394184.0992858.0530022.1507301.007553.0276722.0137749.0562705.0187459.0852958.0022403.013993.0042634.0273905.006014.040829.005538.0418638.0083781.0851877.0083781.129175v-.0000007 5c0 1.6568549-1.3431458 3-3 3h-16c-1.65685425 0-3-1.3431451-3-3v-5l.00672773-.1166204c.0005112-.0044013.00105097-.0087938.00161913-.0131774l-.00834686.1297978c0-.056911.00485776-.1135645.01444559-.1693587.00491747-.0296699.01113937-.0582682.01855477-.0863818.00395835-.0140283.0081049-.0284161.01257495-.042711.00925982-.0305019.02005589-.0599845.03216131-.0887811.00269404-.0057588.00551327-.0122913.00840359-.0187966l3.46979414-7.80953666c.48112742-1.08557868 1.5572908-1.78524449 2.74406565-1.78443414zm-13.6993168 15.0000002c0 .5522852.44771525 1 1 1h16c.5522847 0 1-.4477148 1-1v-4.0000002l-4.4648162.0000007-1.7031335 2.5547002c-.1854658.2781987-.4976971.4452998-.8320503.4452998h-4c-.33435319 0-.64658452-.1671011-.83205029-.4452998l-1.70313347-2.5547002-4.46481624-.0000007zm13.7-12.99999997h-9.40068321c-.3958882-.00027024-.75466534.23303293-.915.59500024l-2.84631679 6.40499953 4.462.0000007c.29255904 0 .56818103.1279368.75676287.3462924l.07528742.0990074 1.70313351 2.5547002h2.9296324l1.7031335-2.5547002c.1854658-.2781987.4976971-.4452998.8320503-.4452998l4.461-.0000007-2.8453168-6.40499953c-.1603347-.36196731-.5191118-.59527048-.9156832-.59500024z" />
    </svg>
  );
};

Inbox.propTypes = propTypes;

export default Inbox;
