import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import EventListener from 'react-event-listener';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import getInitials from '../../utils/getInitials';
import IconPerson from '../icons/Person';
import IconLogout from '../icons/Logout';
import version from '../../version.json';
import { selectors as userSelectors } from '../../state/ducks/user';
import { AppState } from '../../state/reducers';
import '../../styles/Drawer.css';

type Props = {
  open: boolean;
  closeDrawer: () => void;
};

const propTypes = {
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
};

const Drawer = (props: Props) => {
  const {
    open = false,
    closeDrawer,
  } = props;

  const { logout } = useAuth0();

  const user = useSelector((state: AppState) => userSelectors.get(state.user));

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.which === 27) {
      closeDrawer();
    }
  };

  const handleLogout = () => logout();

  return (
    <section className={classNames('drawer', { open })}>
      <section className="overlay" onClick={closeDrawer} role="presentation" />
      <nav>
        <div>
          <section className="drawer-header">
            {
              user.picture
                ? (
                  <img src={user.picture} alt="user" className="avatar" />
                )
                : (
                  <div className="avatar contrast">
                    <p className="button-accent">{getInitials(user.name || '')}</p>
                  </div>
                )
            }
            <h1 className="heading-large-contrast">{user.name}</h1>
          </section>
          <NavLink to="/" exact onClick={closeDrawer}>
            <section>
              <IconPerson />
              <p className="heading">Coachees</p>
            </section>
          </NavLink>
          {/* <NavLink to="/settings" onClick={closeDrawer}>
            <section>
              <IconPerson />
              <p className="heading">Instellingen</p>
            </section>
          </NavLink> */}
        </div>
        <div>
          <button type="button" className="logout" onClick={handleLogout}>
            <IconLogout />
            <p className="heading">Log uit</p>
          </button>
          <p className="body-light version">
            versie:
            &nbsp;
            {version.hash}
          </p>
        </div>
      </nav>
      {
        open
          ? <EventListener target="window" onKeyUp={handleKeyUp} />
          : null
      }
    </section>
  );
};

Drawer.propTypes = propTypes;

export default Drawer;
