import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Header from '../Header';
import Users from './Users';
import EmptyStateUsers from './EmptyStateUsers';
import iconMenu from '../../assets/menu.svg';
import { AppState } from '../../state/reducers';
import { selectors as usersSelectors } from '../../state/ducks/users';
import { actions as uiActions } from '../../state/ducks/ui';

const propTypes = {
  children: PropTypes.node,
};

type Props = {
  children: ReactNode;
};

const Admin = (props: Props) => {
  const { children } = props;

  const users = useSelector((state: AppState) => usersSelectors.getAll(state.users));

  const dispatch = useDispatch();

  const toggleMenu = () => dispatch(uiActions.toggleDrawer());

  const header = (
    <Header
      leftButton={(
        <button type="button" className="header-button" onClick={toggleMenu}>
          <img src={iconMenu} alt="menu" className="icon" />
        </button>
      )}
    />
  );

  const renderContent = () => {
    if (children) {
      return (
        <section className="medium row">
          <section className="list-container">
            {header}
            {!isEmpty(users) ? <Users /> : <EmptyStateUsers />}
          </section>
          {children}
        </section>
      );
    }

    return (
      <>
        {header}
        {!isEmpty(users) ? <Users /> : <EmptyStateUsers />}
      </>
    );
  };

  return (
    <section className="page admin">
      {renderContent()}
    </section>
  );
};

Admin.propTypes = propTypes;

export default Admin;
