import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import Header from './Header';
import PlanForm from './PlanForm';
import iconArrowBack from '../assets/arrow-back.svg';
import { plansCreate } from '../state/operations/plans';
import { AppState } from '../state/reducers';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { PlanSubmitType } from '../types';

const AddLog = () => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: PlanSubmitType) => {
    setLoading(true);

    const token = await getAccessTokenSilently();

    return dispatch(plansCreate(token, data, (plan) => {
      setLoading(false);

      history.push(`/plan/${plan.planId}`);
    }));
  };

  if (medium) {
    return <PlanForm onSubmit={onSubmit} loading={loading} />;
  }

  return (
    <section className="page add-plan">
      <Header
        leftButton={(
          <Link to="/plan">
            <img src={iconArrowBack} alt="back" className="icon" />
          </Link>
        )}
      />
      <PlanForm onSubmit={onSubmit} loading={loading} />
    </section>
  );
};

export default AddLog;
