import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from './Button';
import users from '../assets/users.jpg';
import getBackgroundStyles from '../utils/getBackgroundStyles';

const EmptyStateNotFound = () => {
  const history = useHistory();

  return (
    <section className="empty-state" style={getBackgroundStyles(users)}>
      <h1 className="heading-large-contrast">Oeps, er is iets mis gegaan...</h1>
      <Button labelText="Ga terug" onClick={() => history.push('/')} />
    </section>
  );
};

export default EmptyStateNotFound;
