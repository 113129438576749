import { useEffect } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import forEach from 'lodash/forEach';
import mapValues from 'lodash/mapValues';
import { Dictionary } from 'lodash';

type Query = {
  [index: string]: string
};

type QueryList = {
  [index: string]: MediaQueryList;
};

type Props = {
  query: Query;
  onChange: (matches: Dictionary<boolean>) => void;
};

const propTypes = {
  query: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

const Media = (props: Props) => {
  const { query, onChange } = props;

  const queryList: QueryList = {};

  const updateMatches = () => {
    const matches = mapValues(queryList, 'matches');

    onChange(matches);
  };

  useEffect(
    () => {
      if (isObject(window)) {
        forEach(query, (value, key) => {
          queryList[key] = window.matchMedia(value);

          queryList[key].addListener(updateMatches);
        });

        updateMatches();

        return () => {
          forEach(queryList, (value, key) => {
            queryList[key].removeListener(updateMatches);
          });
        };
      }

      return () => {};
    },
    [],
  );

  return null;
};

Media.propTypes = propTypes;

export default Media;
