import * as actionTypes from './actionTypes';
import { FeedbackType } from '../../../types';
import {
  FeedbackAddActionType,
  FeedbackUpdateActionType,
  FeedbackRemoveActionType,
  FeedbackClearActionType,
} from './types';

const add = (payload: FeedbackType[]): FeedbackAddActionType => ({
  type: actionTypes.ADD,
  payload,
});

const update = (payload: FeedbackType[]): FeedbackUpdateActionType => ({
  type: actionTypes.UPDATE,
  payload,
});

const remove = (payload: string | string[]): FeedbackRemoveActionType => ({
  type: actionTypes.REMOVE,
  payload,
});

const clear = (): FeedbackClearActionType => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  update,
  remove,
  clear,
};
