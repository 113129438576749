import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import EMPTY_ARRAY from '../../../utils/empty-array';
import EMPTY_OBJECT from '../../../utils/empty-object';
import { UserType } from '../../../types';
import { UsersState } from './types';

const getAll = (state: UsersState): UserType[] => {
  if (isEmpty(state)) {
    return EMPTY_ARRAY as unknown as UserType[];
  }

  return orderBy(state, 'lastEntry', 'desc');
};

const getById = (state: UsersState, id: string): UserType => (
  get(state, id) || EMPTY_OBJECT
);

export {
  getAll,
  getById,
};
