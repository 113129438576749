import React, { useState, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import reduce from 'lodash/reduce';
import Textarea from 'react-textarea-autosize';

import Header from '../Header';
import Button from '../Button';
import { AppState } from '../../state/reducers';
import { selectors as usersSelectors } from '../../state/ducks/users';
import { adminFeedbackCreate } from '../../state/operations/admin/feedback';
import iconArrowBack from '../../assets/arrow-back.svg';
import { feedbackSchema } from '../../utils/schemas';

type Errors = {
  feedback?: string;
};

const FeedbackForm = () => {
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const { userId, logId } = useParams();

  const history = useHistory();

  const user = useSelector((state: AppState) => usersSelectors.getById(state.users, userId));

  const [feedback, setFeedback] = useState('');
  const [errors, setErrors] = useState<Errors>({});
  const [loading, setLoading] = useState(false);

  const adminFeedbackCreateAsync = async (id: string, description: string, lId: string) => {
    setLoading(true);

    const token = await getAccessTokenSilently();

    return dispatch(adminFeedbackCreate(token, { userId: id, description, logId: lId }, () => {
      setLoading(false);

      history.goBack();
    }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setErrors({});

    try {
      feedbackSchema.validateSync({
        feedback,
      }, {
        abortEarly: false,
        stripUnknown: true,
      });
    } catch (error) {
      const validationErrors = reduce(
        error.inner,
        (result, err) => ({ ...result, [err.path]: err.message }),
        {},
      );

      return setErrors(validationErrors);
    }

    return adminFeedbackCreateAsync(userId, feedback, logId);
  };

  return (
    <form className="page add-feedback" onSubmit={handleSubmit}>
      <Header
        leftButton={(
          <button type="button" onClick={history.goBack} className="header-button">
            <img src={iconArrowBack} alt="back" className="icon" />
          </button>
        )}
        rightButton={(
          <Button type="text" labelText="Bewaar" onClick={handleSubmit} disabled={loading} />
        )}
        text={user.name}
      />
      <section className="content">
        <section>
          <h1 className="heading">Feedback</h1>
          <Textarea
            className="input"
            minRows={2}
            value={feedback}
            onChange={(event) => setFeedback(event.currentTarget.value)}
          />
          {
            errors.feedback
            && (
              <p className="body-error">{errors.feedback}</p>
            )
          }
        </section>
        <Button labelText="Bewaar" onClick={handleSubmit} disabled={loading} />
      </section>
    </form>
  );
};

export default FeedbackForm;
