import React from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import ListItem from './ListItem';
import EmptyStatePlans from './EmptyStatePlans';
import formatDate from '../utils/formatDate';
import '../styles/ListItem.css';
import { PlanType } from '../types';

const propTypes = {
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string,
  userId: PropTypes.string,
};

type Props = {
  plans: PlanType[];
  name?: string;
  userId?: string;
};

const PlanList = (props: Props) => {
  const {
    plans,
    name = '',
    userId = '',
  } = props;

  if (isEmpty(plans)) {
    return <EmptyStatePlans name={name} />;
  }

  return (
    <section className="list">
      {map(plans, (plan, index) => {
        const isLastItem = (index + 1) === size(plans);

        return (
          <ListItem
            key={plan.planId}
            primaryText={`${formatDate(new Date(plan.startDate), 'dd LLLL')} - ${formatDate(new Date(plan.endDate), 'dd LLLL')}`}
            secondaryText={plan.exercises}
            to={
              userId
                ? `/user/${userId}/plan/${plan.planId}`
                : `/plan/${plan.planId}`
            }
            isLastItem={isLastItem}
          />
        );
      })}
    </section>
  );
};

PlanList.propTypes = propTypes;

export default PlanList;
