import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string,
};

type Props = {
  fill?: string;
};

const Logout = (props: Props) => {
  const { fill = '#11171C' } = props;

  return (
    <svg height="24" viewBox="0 0 24 24" width="24" fill={fill}>
      <path id="a" d="m10 1c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-5c-.55228475 0-1 .44771525-1 1v16c0 .5522847.44771525 1 1 1h5c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-5c-1.65685425 0-3-1.3431458-3-3v-16c0-1.65685425 1.34314575-3 3-3zm6.2928932 6.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0l4 3.99999998c.0284663.0284663.0552244.0586409.0801072.0903567.0055015.0069077.0110326.0141471.0164514.0214435.0178445.0241759.0346159.0490316.0502672.0746464.0074605.0120471.0145686.0242658.0213962.0365981.0108773.0197682.0212946.0401707.0310174.060963.0079748.0170006.0154574.0343035.0224283.0517631.0079098.0197568.015103.0395383.0216788.0595996.004818.0148851.009449.0302731.0137016.0457434.0060115.0215565.0112053.0432469.0156799.0651989.003463.0174188.0065371.0348451.0091474.0523272.0072566.0481048.0110178.0978466.0110178.1484669l-.0037021-.0862308c.0015457.0179653.0026074.0359644.003185.0539762l.0005171.0322546c0 .0112225-.0001849.0224019-.0005517.0335352-.0005711.0170558-.0015933.0343879-.0030643.051689-.00182.0218332-.0042899.0428902-.0074033.063737-.0026088.0169878-.0056829.0344141-.0092208.0517646-.0043997.0220203-.0095935.0437107-.0154809.0651138-.0043767.0156237-.0090077.0310117-.0140171.0463029-.0063844.0196552-.0135776.0394367-.0213659.058916-.0070924.0177371-.014575.03504-.0225694.052163-.0097032.0206699-.0201205.0410724-.0312024.0610555-.006623.0121174-.0137311.0243361-.0211197.0364318-.0157232.0255662-.0324946.0504219-.050318.0744505-.0289995.0391663-.0611857.0765534-.0965797.1119475l.0801072-.0903567c-.0022851.0029127-.0045861.0058123-.0069028.0086989l-.0732044.0816578-4 4c-.3905243.3905243-1.0236893.3905243-1.4142136 0s-.3905243-1.0236893 0-1.4142136l2.2921068-2.2928932h-9.585c-.51283584 0-.93550716-.3860402-.99327227-.8833789l-.00672773-.1166211c0-.5522847.44771525-1 1-1h9.585l-2.2921068-2.29289322c-.3604839-.36048396-.3882135-.92771502-.0831886-1.32000622z" />
    </svg>
  );
};

Logout.propTypes = propTypes;

export default Logout;
