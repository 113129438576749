import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash/round';
import isNumber from 'lodash/isNumber';
import { sprintf } from 'sprintf-js';

import buttonMinus from '../assets/button-minus.svg';
import buttonPlus from '../assets/button-plus.svg';
import '../styles/NumericInput.css';

const propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  decimals: PropTypes.number,
  step: PropTypes.number,
  labelText: PropTypes.string.isRequired,
};

type Props = {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  decimals?: number;
  step?: number;
  labelText: string;
};

const NumericInput = (props: Props) => {
  const {
    value,
    onChange,
    minValue,
    maxValue,
    decimals = 1,
    step = 0.5,
    labelText,
  } = props;

  const incrementValue = () => {
    if (isNumber(maxValue) && (value + step) > maxValue) {
      return false;
    }

    return onChange(round(value + step, decimals));
  };

  const decrementValue = () => {
    if (isNumber(minValue) && (value - step) < minValue) {
      return false;
    }

    return onChange(round(value - step, decimals));
  };

  return (
    <section className="numeric-input">
      <h2 className="heading">{labelText}</h2>
      <section>
        <button type="button" onClick={decrementValue}><img src={buttonMinus} alt="minus" /></button>
        <p className="heading">{sprintf(`%.${decimals}f`, value)}</p>
        <button type="button" onClick={incrementValue}><img src={buttonPlus} alt="plus" /></button>
      </section>
    </section>
  );
};

NumericInput.propTypes = propTypes;

export default NumericInput;
