import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Dispatch } from 'redux';
import differenceBy from 'lodash/differenceBy';
import map from 'lodash/map';

import { actions as feedbackActions, selectors as feedbackSelectors } from '../ducks/feedback';
import logAxiosError from '../../utils/logAxiosError';
import { AppState } from '../reducers';

const feedbackListResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  getState: () => AppState,
) => {
  const { ok, feedback } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  if (isEmpty(feedback)) {
    return dispatch(feedbackActions.clear());
  }

  const existingFeedback = feedbackSelectors.getAll(getState().feedback);

  const feedbackToRemove = map(differenceBy(existingFeedback, feedback, 'feedbackId'), 'feedbackId');

  dispatch(feedbackActions.remove(feedbackToRemove));
  return dispatch(feedbackActions.add(feedback));
};

const feedbackList = (token: string) => (
  (dispatch: Dispatch, getState: () => AppState) => (
    axios({
      method: 'get',
      url: '/api/v1/feedback/list',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => feedbackListResult(dispatch, result, getState),
      logAxiosError,
    )
  )
);

export {
  feedbackList, // eslint-disable-line import/prefer-default-export
};
