import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import differenceBy from 'lodash/differenceBy';
import noop from 'lodash/noop';
import { Dispatch } from 'redux';

import { actions as usersActions, selectors as usersSelectors } from '../../ducks/users';
import { AppState } from '../../reducers';
import logAxiosError from '../../../utils/logAxiosError';

const adminUsersListResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  getState: () => AppState,
) => {
  const { ok, users } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  if (isEmpty(users)) {
    return dispatch(usersActions.clear());
  }

  const existingUsers = usersSelectors.getAll(getState().users);

  const usersToRemove = map(differenceBy(existingUsers, users, 'userId'), 'userId');

  dispatch(usersActions.remove(usersToRemove));
  return dispatch(usersActions.add(users));
};

const adminUsersList = (token: string) => (
  (dispatch: Dispatch, getState: () => AppState) => (
    axios({
      method: 'get',
      url: '/api/v1/admin/users/list',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => adminUsersListResult(dispatch, result, getState),
      logAxiosError,
    )
  )
);

const adminUsersUpdateResult = (dispatch: Dispatch, result: AxiosResponse, done = noop) => {
  const { ok, user } = get(result, 'data', {});

  if (!ok) {
    return done();
  }

  dispatch(usersActions.update([user]));

  return done();
};

const adminUsersUpdate = (
  token: string,
  data: { inactive: boolean, userId: string },
  done = noop,
) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/admin/users/update',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }).then(
      (result) => adminUsersUpdateResult(dispatch, result, done),
      logAxiosError,
    )
  )
);

export {
  adminUsersList,
  adminUsersUpdate,
};
