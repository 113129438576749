import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { MediaState, MediaActionTypes } from './types';

/* State Shape
{
  [query]: true | false,
  ...
}
*/

const initialState: MediaState = {};

const reducer: Reducer<
MediaState,
MediaActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: return action.payload;
    default: return state;
  }
};

export default reducer;
