import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as userSelectors } from '../state/ducks/user';
import { selectors as feedbackSelectors } from '../state/ducks/feedback';
import { actions as uiActions } from '../state/ducks/ui';
import { AppState } from '../state/reducers';
import iconMenu from '../assets/menu.svg';

const DrawerButton = () => {
  const dispatch = useDispatch();

  const toggleMenu = () => dispatch(uiActions.toggleDrawer());

  const user = useSelector((state: AppState) => userSelectors.get(state.user));
  const unread = useSelector(
    (state: AppState) => feedbackSelectors.unread(state.feedback, user.lastRead as Date),
  );

  return (
    <div className="drawer-button">
      <button type="button" className="header-button" onClick={toggleMenu}>
        <img src={iconMenu} alt="menu" className="icon" />
      </button>
      {
        unread > 0
          ? <div className="badge">{unread > 9 ? '9+' : unread}</div>
          : null
      }
    </div>
  );
};

export default DrawerButton;
