export default [
  {
    value: '1',
    label: '1 week',
  },
  {
    value: '2',
    label: '2 weken',
  },
  {
    value: '3',
    label: '3 weken',
  },
  {
    value: '4',
    label: '4 weken',
  },
  {
    value: '5',
    label: '5 weken',
  },
  {
    value: '6',
    label: '6 weken',
  },
  {
    value: '7',
    label: '7 weken',
  },
  {
    value: '8',
    label: '8 weken',
  },
  {
    value: '9',
    label: '9 weken',
  },
  {
    value: '10',
    label: '10 weken',
  },
];
