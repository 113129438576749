import React, { useState, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import getInitials from '../../utils/getInitials';
import { adminUsersUpdate } from '../../state/operations/admin/users';
import { UserType } from '../../types';

const propTypes = {
  user: PropTypes.shape({
    picture: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    about: PropTypes.string,
    userId: PropTypes.string,
    inactive: PropTypes.bool,
  }).isRequired,
};

type Props = {
  user: UserType;
};

const About = (props: Props) => {
  const { user } = props;

  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const adminUsersUpdateAsync = async (inactive: boolean) => {
    setLoading(true);

    const token = await getAccessTokenSilently();

    return dispatch(adminUsersUpdate(
      token,
      { userId: user.userId, inactive },
      () => setLoading(false),
    ));
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = get(e, 'target.checked');

    adminUsersUpdateAsync(!checked);
  };

  return (
    <section className="profile">
      <header>
        {
          user.picture
            ? (
              <img src={user.picture} alt="user" className="avatar large" />
            )
            : (
              <div className="avatar large contrast">
                <p className="button-accent">{getInitials(user.name)}</p>
              </div>
            )
        }
        <section>
          <h1 className="heading">{user.name}</h1>
          <p className="body-light">{user.email}</p>
        </section>
      </header>
      {
        user.about
          ? (
            <>
              <h1 className="heading">Over mij</h1>
              <p className="body">{user.about}</p>
            </>
          )
          : null
      }
      <label htmlFor="inactive" className="checkbox">
        <span className="heading">Actief</span>
        <input
          type="checkbox"
          id="inactive"
          name="inactive"
          onChange={(event) => handleCheckboxChange(event)}
          checked={!user.inactive}
          disabled={loading}
        />
      </label>
    </section>
  );
};

About.propTypes = propTypes;

export default About;
