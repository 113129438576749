import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import * as utils from './utils';
import {
  UsersState,
  UsersActionTypes,
  UsersAddActionType,
  UsersUpdateActionType,
  UsersRemoveActionType,
} from './types';

/* State Shape
{
  [userId]: user,
  ...
}
*/

const initialState: UsersState = {};

const reducer: Reducer<
UsersState,
UsersActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as UsersAddActionType);
    case actionTypes.UPDATE: return utils.update(state, action as UsersUpdateActionType);
    case actionTypes.REMOVE: return utils.remove(state, action as UsersRemoveActionType);
    case actionTypes.CLEAR: return {};
    default: return state;
  }
};

export default reducer;
