import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';

import {
  PlansState,
  PlansAddActionType,
  PlansUpdateActionType,
  PlansRemoveActionType,
} from './types';

const add = (state: PlansState, action: PlansAddActionType): PlansState => {
  const { payload } = action;

  const logs = reduce(
    payload,
    (result, item) => (
      assignIn(
        result,
        {
          [item.planId]: item,
        },
      )
    ),
    {},
  );

  return {
    ...state,
    ...logs,
  };
};

const update = (state: PlansState, action: PlansUpdateActionType): PlansState => {
  const { payload } = action;

  const logs = reduce(
    payload,
    (result, item) => (
      assignIn(
        result,
        {
          [item.planId]: item,
        },
      )
    ),
    {},
  );

  return {
    ...state,
    ...logs,
  };
};

const remove = (state: PlansState, action: PlansRemoveActionType): PlansState => {
  let { payload } = action;

  if (!isArray(payload)) {
    payload = [payload];
  }

  return omit(state, payload);
};

export {
  add,
  update,
  remove,
};
