import * as actionTypes from './actionTypes';
import { UserType } from '../../../types';
import {
  UsersAddActionType,
  UsersUpdateActionType,
  UsersRemoveActionType,
  UsersClearActionType,
} from './types';

const add = (payload: UserType[]): UsersAddActionType => ({
  type: actionTypes.ADD,
  payload,
});

const update = (payload: UserType[]): UsersUpdateActionType => ({
  type: actionTypes.UPDATE,
  payload,
});

const remove = (payload: string | string[]): UsersRemoveActionType => ({
  type: actionTypes.REMOVE,
  payload,
});

const clear = (): UsersClearActionType => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  update,
  remove,
  clear,
};
