import React, { useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import nl2br from 'react-nl2br';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';

import Header from '../Header';
import EmptyStateNotFound from '../EmptyStateNotFound';
import Button from '../Button';
import { AppState } from '../../state/reducers';
import { selectors as usersSelectors } from '../../state/ducks/users';
import { selectors as logsSelectors } from '../../state/ducks/logs';
import { selectors as feedbackSelectors } from '../../state/ducks/feedback';
import iconArrowBack from '../../assets/arrow-back.svg';
import iconAdd from '../../assets/add.svg';
import iconChevronLeft from '../../assets/chevron-left.svg';
import iconChevronRight from '../../assets/chevron-right.svg';
import formatDate from '../../utils/formatDate';
import toDate from '../../utils/toDate';
import { adminLogsReadAt } from '../../state/operations/admin/logs';

const Log = () => {
  const { logId, userId } = useParams();

  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const history = useHistory();

  const log = useSelector((state: AppState) => logsSelectors.getById(state.logs, logId));
  const logs = useSelector((state: AppState) => logsSelectors.getByUserId(state.logs, userId));
  const user = useSelector((state: AppState) => usersSelectors.getById(state.users, userId));
  const feedback = useSelector((state: AppState) => (
    feedbackSelectors.getByLogId(state.feedback, logId)
  ));

  useEffect(() => {
    const logsReadAscync = async (id: string) => {
      const token = await getAccessTokenSilently();

      dispatch(adminLogsReadAt(token, id));
    };

    if (logId && !log.readAt) {
      logsReadAscync(logId);
    }
  }, [logId, log]);

  const logIndex = findIndex(logs, { logId });

  const nextLog = logs[logIndex + 1];
  const previousLog = logs[logIndex - 1];

  let content = <EmptyStateNotFound />;

  if (!isEmpty(log)) {
    content = (
      <section className="content">
        <section>
          <nav className="prev-next">
            {
              !isEmpty(nextLog)
                ? (
                  <Link to={`/user/${userId}/log/${nextLog.logId}`} className="button-accent">
                    <img src={iconChevronLeft} alt="left" />
                    Vorige
                  </Link>
                )
                : <span />
            }
            {
              !isEmpty(previousLog)
                ? (
                  <Link to={`/user/${userId}/log/${previousLog.logId}`} className="button-accent">
                    Volgende
                    <img src={iconChevronRight} alt="right" />
                  </Link>
                )
                : <span />
            }
          </nav>
          <h1 className="heading-large">{formatDate(toDate(log.date), 'EEEE dd LLLL yyyy')}</h1>
          <h2 className="heading">Cijfer van de dag</h2>
          <p className="body margin">{log.grade}</p>
          {
            log.pulse
              ? (
                <>
                  <h2 className="heading">Ochtendpuls</h2>
                  <p className="body margin">{log.pulse}</p>
                </>
              )
              : null
          }
          <h2 className="heading">Activiteiten</h2>
          <p className="body margin">{log.activities}</p>
          {
            log.description
              ? (
                <>
                  <h2 className="heading">Gevoel van de dag</h2>
                  <p className="body margin">{nl2br(log.description)}</p>
                </>
              )
              : null
          }
          {
            log.gratitude
              ? (
                <>
                  <h2 className="heading">Extra deelmoment</h2>
                  <p className="body margin">{nl2br(log.gratitude)}</p>
                </>
              )
              : null
          }
          {
            !isEmpty(feedback)
              ? (
                <>
                  <hr className="divider margin" />
                  <h2 className="heading">Feedback</h2>
                  <p className="body-light margin">Gegeven op {formatDate(toDate(feedback.createdAt), 'EEEE dd LLLL')}</p>
                  <p className="body margin">{nl2br(feedback.description)}</p>
                </>
              )
              : null
          }
        </section>
        <Button
          labelText="Geef feedback"
          onClick={() => history.push(`/user/${userId}/feedback/${logId}`)}
          disabled={!isEmpty(feedback)}
        />
      </section>
    );
  }

  return (
    <section className="page log">
      <Header
        leftButton={(
          <Link to={`/user/${userId}`}>
            <img src={iconArrowBack} alt="back" className="icon" />
          </Link>
        )}
        rightButton={(
          <Link to={`/user/${userId}/feedback/${logId}`} className={classNames({ disabled: !isEmpty(feedback) })}>
            <img src={iconAdd} alt="add" className="icon" />
          </Link>
        )}
        text={user.name}
      />
      {content}
    </section>
  );
};

export default Log;
