import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from './Button';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { AppState } from '../state/reducers';
import logs from '../assets/logs.jpg';
import getBackgroundStyles from '../utils/getBackgroundStyles';

type Props = {
  name?: string;
};

const propTypes = {
  name: PropTypes.string,
};

const EmptyStateLogs = (props: Props) => {
  const { name = '' } = props;

  const history = useHistory();

  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  let text = 'Je heb nog niks in je logboek staan';

  if (name) {
    text = `Het logboek van ${name} is nog leeg`;
  }

  return (
    <section className="empty-state" style={getBackgroundStyles(logs)}>
      <h1 className="heading-large-contrast">{text}</h1>
      {
        !medium
          ? <Button labelText="Voeg iets toe" onClick={() => history.push('/add-log')} />
          : null
      }
    </section>
  );
};

EmptyStateLogs.propTypes = propTypes;

export default EmptyStateLogs;
