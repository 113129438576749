import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import useInterval from '@use-it/interval';

import { adminUsersList } from '../state/operations/admin/users';
import { adminFeedbackList } from '../state/operations/admin/feedback';
import { adminLogsList } from '../state/operations/admin/logs';
import { adminPlansList } from '../state/operations/admin/plans';
import Drawer from '../components/admin/Drawer';
import EmptyStateUser from '../components/admin/EmptyStateUser';
import Admin from '../components/admin/Admin';
import AdminUser from '../components/admin/User';
import AdminLog from '../components/admin/Log';
import AdminPlan from '../components/admin/Plan';
import AdminFeedback from '../components/admin/FeedbackForm';
import { actions as uiActions, selectors as uiSelectors } from '../state/ducks/ui';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { interval } from '../utils/constants';

const AdminRouter = () => {
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const drawerOpen = useSelector((state) => uiSelectors.drawerOpen(state.ui));

  const medium = useSelector((state) => mediaSelectors.getQuery(state.media, 'medium'));

  const toggleDrawer = () => dispatch(uiActions.toggleDrawer());

  useEffect(
    () => {
      const getDataAsync = async () => {
        const token = await getAccessTokenSilently();

        dispatch(adminUsersList(token));
        dispatch(adminFeedbackList(token));
        dispatch(adminLogsList(token));
        dispatch(adminPlansList(token));
      };

      getDataAsync();
    },
    [],
  );

  useInterval(
    () => {
      const getDataAsync = async () => {
        const token = await getAccessTokenSilently();

        dispatch(adminUsersList(token));
        dispatch(adminFeedbackList(token));
        dispatch(adminLogsList(token));
        dispatch(adminPlansList(token));
      };

      getDataAsync();
    },
    interval,
  );

  if (medium) {
    return (
      <BrowserRouter>
        <Admin>
          <Switch>
            <Route path="/user/:userId/feedback/:logId?" component={AdminFeedback} />
            <Route path="/user/:userId/log/:logId" component={AdminLog} />
            <Route path="/user/:userId/plan/:planId" component={AdminPlan} />
            <Route path="/user/:userId" component={AdminUser} />
            <Route path="/" component={EmptyStateUser} />
          </Switch>
        </Admin>
        <Drawer open={drawerOpen} closeDrawer={toggleDrawer} />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/user/:userId/feedback/:logId?" component={AdminFeedback} />
        <Route path="/user/:userId/log/:logId" component={AdminLog} />
        <Route path="/user/:userId/plan/:planId" component={AdminPlan} />
        <Route path="/user/:userId" component={AdminUser} />
        <Route path="/" component={Admin} />
      </Switch>
      <Drawer open={drawerOpen} closeDrawer={toggleDrawer} />
    </BrowserRouter>
  );
};

export default AdminRouter;
