import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import filter from 'lodash/filter';
import first from 'lodash/first';
import isSameDay from 'date-fns/isSameDay';
import toArray from 'lodash/toArray';

import EMPTY_ARRAY from '../../../utils/empty-array';
import EMPTY_OBJECT from '../../../utils/empty-object';
import { LogsState } from './types';
import { LogType } from '../../../types';

const getAll = (state: LogsState): LogType[] => {
  if (isEmpty(state)) {
    return EMPTY_ARRAY as unknown as LogType[];
  }

  return toArray(state);
};

const getById = (state: LogsState, id: string): LogType => (
  get(state, id) || EMPTY_OBJECT
);

const getByDateSelector = (state: LogsState, date: Date): LogType => {
  const logByDate = find(state, (log) => isSameDay(new Date(log.date), date)) || EMPTY_OBJECT;

  return logByDate as LogType;
};

const getByDate = createSelector(
  (state: LogsState) => state,
  (state: LogsState, date: Date) => date,
  getByDateSelector,
);

const getLatestSelector = (state: LogsState): LogType => {
  const latestLog = first(orderBy(state, ['date'], ['desc'])) || EMPTY_OBJECT;

  return latestLog as LogType;
};

const getLatest = createSelector(
  (state: LogsState) => state,
  getLatestSelector,
);

const getSortedSelector = (state: LogsState): LogType[] => orderBy(state, ['date'], ['desc']);

const getSorted = createSelector(
  (state: LogsState) => state,
  getSortedSelector,
);

const getByUserIdSelector = (state: LogsState, userId: string): LogType[] => (
  orderBy(filter(state, { userId }), ['date'], ['desc'])
);

const getByUserId = createSelector(
  (state: LogsState) => state,
  (state: LogsState, userId: string) => userId,
  getByUserIdSelector,
);

export {
  getAll,
  getById,
  getByDate,
  getLatest,
  getSorted,
  getByUserId,
};
