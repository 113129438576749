import * as actionTypes from './actionTypes';
import { PlanType } from '../../../types';
import {
  PlansAddActionType,
  PlansUpdateActionType,
  PlansRemoveActionType,
  PlansClearActionType,
} from './types';

const add = (payload: PlanType[]): PlansAddActionType => ({
  type: actionTypes.ADD,
  payload,
});

const update = (payload: PlanType[]): PlansUpdateActionType => ({
  type: actionTypes.UPDATE,
  payload,
});

const remove = (payload: string | string[]): PlansRemoveActionType => ({
  type: actionTypes.REMOVE,
  payload,
});

const clear = (): PlansClearActionType => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  update,
  remove,
  clear,
};
