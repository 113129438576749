import React from 'react';

import user from '../../assets/user.jpg';
import getBackgroundStyles from '../../utils/getBackgroundStyles';

const EmptyStateUser = () => (
  <section className="empty-state full-height" style={getBackgroundStyles(user)}>
    <h1 className="heading-large-contrast">Kies een coachee</h1>
  </section>
);

export default EmptyStateUser;
