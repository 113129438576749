import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import filter from 'lodash/filter';
import parseInt from 'lodash/parseInt';

import LogList from '../LogList';
import { LogType } from '../../types';

const propTypes = {
  name: PropTypes.string,
  userId: PropTypes.string,
  logs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = {
  logs: LogType[];
  name: string;
  userId: string;
};

const options = [
  {
    value: 'grade-gt',
    label: 'Cijfer, hoger dan',
  },
  {
    value: 'grade-lt',
    label: 'Cijfer, lager dan',
  },
  {
    value: 'pulse-gt',
    label: 'Ochtendpols, hoger dan',
  },
  {
    value: 'pulse-lt',
    label: 'Ochtendpols, lager dan',
  },
];

const Logs = (props: Props) => {
  const { logs, name, userId } = props;
  const [query, setQuery] = useState('');
  const [option, setOption] = useState('grade-gt');

  let list = logs;

  if (query) {
    list = filter(logs, (log) => {
      const parsedQuery = parseInt(query);

      switch (option) {
        case 'grade-lt': return log.grade <= parsedQuery;
        case 'pulse-gt': {
          if (log.pulse) {
            return parseInt(log.pulse) >= parsedQuery;
          }

          return false;
        }
        case 'pulse-lt': {
          if (log.pulse) {
            return parseInt(log.pulse) <= parsedQuery;
          }

          return false;
        }
        default: return log.grade >= parsedQuery;
      }
    });
  }

  return (
    <section className="logs">
      <section className="search">
        <h1 className="heading">Zoek op</h1>
        <div className="row">
          <Dropdown
            options={options}
            onChange={(o) => setOption(o.value)}
            value={option}
          />
          <input
            className="input"
            type="number"
            value={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
          />
        </div>
      </section>
      <hr className="divider" />
      <LogList
        logs={list}
        name={name}
        userId={userId}
        showEmptyState={false}
      />
    </section>
  );
};

Logs.propTypes = propTypes;

export default Logs;
