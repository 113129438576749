import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { Dispatch } from 'redux';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import differenceBy from 'lodash/differenceBy';
import map from 'lodash/map';

import { actions as plansActions, selectors as plansSelectors } from '../ducks/plans';
import logAxiosError from '../../utils/logAxiosError';
import { PlanType, PlanSubmitType } from '../../types';
import { AppState } from '../reducers';

const plansListResult = (dispatch: Dispatch, result: AxiosResponse, getState: () => AppState) => {
  const { ok, plans } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  if (isEmpty(plans)) {
    return dispatch(plansActions.clear());
  }

  const existingPlans = plansSelectors.getAll(getState().plans);

  const plansToRemove = map(differenceBy(existingPlans, plans, 'planId'), 'planId');

  dispatch(plansActions.remove(plansToRemove));
  return dispatch(plansActions.add(plans));
};

const plansList = (token: string) => (
  (dispatch: Dispatch, getState: () => AppState) => (
    axios({
      method: 'get',
      url: '/api/v1/plans/list',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => plansListResult(dispatch, result, getState),
      logAxiosError,
    )
  )
);

const plansUpdateResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  done: (plan: PlanType) => void,
) => {
  const { ok, plan } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  dispatch(plansActions.update([plan]));

  return done(plan);
};

const plansUpdate = (token: string, plan: PlanSubmitType, done = noop) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/plans/update',
      data: plan,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => plansUpdateResult(dispatch, result, done),
      logAxiosError,
    )
  )
);

const plansCreateResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  done: (plan: PlanType) => void,
) => {
  const { ok, plan } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  dispatch(plansActions.add([plan]));

  return done(plan);
};

const plansCreate = (token: string, plan: PlanSubmitType, done = noop) => (
  (dispatch: Dispatch) => (
    axios({
      method: 'post',
      url: '/api/v1/plans/create',
      data: plan,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => plansCreateResult(dispatch, result, done),
      logAxiosError,
    )
  )
);

export {
  plansList,
  plansCreate,
  plansUpdate,
};
