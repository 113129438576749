import fromUnixTime from 'date-fns/fromUnixTime';
import parseISO from 'date-fns/parseISO';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isDate from 'lodash/isDate';

import { TDate } from '../types';

const toDate = (date: TDate): Date => {
  if (isString(date)) {
    return parseISO(date);
  }

  if (isNumber(date)) {
    return fromUnixTime(date);
  }

  if (isDate(date)) {
    return date;
  }

  return new Date();
};

export default toDate;
