import * as actionTypes from './actionTypes';
import { UserType } from '../../../types';
import {
  UserAddActionType,
  UserClearActionType,
} from './types';

const add = (payload: UserType): UserAddActionType => ({
  type: actionTypes.ADD,
  payload,
});

const clear = (): UserClearActionType => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  clear,
};
