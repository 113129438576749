import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from './Header';
import Plan from './Plan';
import AddPlan from './AddPlan';
import PlanList from './PlanList';
import DrawerButton from './DrawerButton';
import iconAdd from '../assets/add.svg';
import { AppState } from '../state/reducers';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { selectors as plansSelectors } from '../state/ducks/plans';

const Plans = () => {
  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  const plans = useSelector((state: AppState) => plansSelectors.getSorted(state.plans));

  const header = (
    <Header
      leftButton={<DrawerButton />}
      rightButton={(
        <Link to={medium ? '/plan' : '/add-plan'}>
          <img src={iconAdd} alt="add" className="icon" />
        </Link>
      )}
    />
  );

  const renderContent = () => {
    if (medium) {
      return (
        <section className="medium row">
          <section className="list-container">
            {header}
            <PlanList plans={plans} />
          </section>
          <Switch>
            <Route path="/plan/:planId" component={Plan} />
            <Route path="/" component={AddPlan} />
          </Switch>
        </section>
      );
    }

    return (
      <>
        {header}
        <PlanList plans={plans} />
      </>
    );
  };

  return (
    <section className="page">
      {renderContent()}
    </section>
  );
};

export default Plans;
