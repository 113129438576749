import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';

import {
  FeedbackState,
  FeedbackAddActionType,
  FeedbackUpdateActionType,
  FeedbackRemoveActionType,
} from './types';

const add = (state: FeedbackState, action: FeedbackAddActionType): FeedbackState => {
  const { payload } = action;

  const feedback = reduce(
    payload,
    (result, item) => assignIn(result, { [item.feedbackId]: item }),
    {},
  );

  return {
    ...state,
    ...feedback,
  };
};

const update = (state: FeedbackState, action: FeedbackUpdateActionType): FeedbackState => {
  const { payload } = action;

  const feedback = reduce(
    payload,
    (result, item) => assignIn(result, { [item.feedbackId]: item }),
    {},
  );

  return {
    ...state,
    ...feedback,
  };
};

const remove = (state: FeedbackState, action: FeedbackRemoveActionType): FeedbackState => {
  let { payload } = action;

  if (!isArray(payload)) {
    payload = [payload];
  }

  return omit(state, payload);
};

export {
  add,
  update,
  remove,
};
