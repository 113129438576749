import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import includes from 'lodash/includes';

import Media from '../components/Media';
import { usersInfo } from '../state/operations/user';
import { actions as mediaActions } from '../state/ducks/media';
import { selectors as userSelectors } from '../state/ducks/user';
import UserRouter from './user';
import AdminRouter from './admin';

const query = {
  medium: '(min-width: 768px)',
};

const Router = () => {
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const handleMediaChange = (media) => dispatch(mediaActions.set(media));

  const user = useSelector((state) => userSelectors.get(state.user));

  useEffect(
    () => {
      const getDataAsync = async () => {
        const token = await getAccessTokenSilently();

        dispatch(usersInfo(token));
      };

      getDataAsync();
    },
    [],
  );

  const userIsAdmin = includes(user.roles, 'admin');

  return (
    <>
      {userIsAdmin ? <AdminRouter /> : <UserRouter />}
      <Media query={query} onChange={handleMediaChange} />
    </>
  );
};

export default Router;
