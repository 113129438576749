import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import differenceBy from 'lodash/differenceBy';
import { Dispatch } from 'redux';

import { actions as plansActions, selectors as plansSelectors } from '../../ducks/plans';
import { AppState } from '../../reducers';
import logAxiosError from '../../../utils/logAxiosError';

const adminPlansListResult = (
  dispatch: Dispatch,
  result: AxiosResponse,
  getState: () => AppState,
) => {
  const { ok, plans } = get(result, 'data', {});

  if (!ok) {
    return false;
  }

  const existingPlans = plansSelectors.getAll(getState().plans);

  const plansToRemove = map(differenceBy(existingPlans, plans, 'planId'), 'planId');

  dispatch(plansActions.remove(plansToRemove));
  return dispatch(plansActions.add(plans));
};

const adminPlansList = (token: string) => (
  (dispatch: Dispatch, getState: () => AppState) => (
    axios({
      method: 'get',
      url: '/api/v1/admin/plans/list',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (result) => adminPlansListResult(dispatch, result, getState),
      logAxiosError,
    )
  )
);

export {
  adminPlansList, // eslint-disable-line import/prefer-default-export
};
