import isEmpty from 'lodash/isEmpty';

import EMPTY_OBJECT from '../../../utils/empty-object';
import { UserState } from './types';

const get = (state: UserState): UserState => {
  if (isEmpty(state)) {
    return EMPTY_OBJECT;
  }

  return state;
};

export {
  get, // eslint-disable-line import/prefer-default-export
};
