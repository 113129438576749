import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from './Header';
import LogList from './LogList';
import Log from './Log';
import AddLog from './AddLog';
import DrawerButton from './DrawerButton';
import iconAdd from '../assets/add.svg';
import { AppState } from '../state/reducers';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { selectors as logsSelectors } from '../state/ducks/logs';

const Logs = () => {
  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  const logs = useSelector((state: AppState) => logsSelectors.getSorted(state.logs));

  const header = (
    <Header
      leftButton={<DrawerButton />}
      rightButton={(
        <Link to={medium ? '/' : '/add-log'}>
          <img src={iconAdd} alt="add" className="icon" />
        </Link>
      )}
    />
  );

  const renderContent = () => {
    if (medium) {
      return (
        <section className="medium row">
          <section className="list-container">
            {header}
            <LogList logs={logs} />
          </section>
          <Switch>
            <Route path="/log/:logId" component={Log} />
            <Route path="/" component={AddLog} />
          </Switch>
        </section>
      );
    }

    return (
      <>
        {header}
        <LogList logs={logs} />
      </>
    );
  };

  return (
    <section className="page">
      {renderContent()}
    </section>
  );
};

export default Logs;
