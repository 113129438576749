import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useAuth0 } from '@auth0/auth0-react';

import { LogSubmitType } from '../types';
import Header from './Header';
import LogForm from './LogForm';
import EmptyStateNotFound from './EmptyStateNotFound';
import { AppState } from '../state/reducers';
import { selectors as logsSelectors } from '../state/ducks/logs';
import { selectors as mediaSelectors } from '../state/ducks/media';
import { logsUpdate } from '../state/operations/logs';
import iconArrowBack from '../assets/arrow-back.svg';
import iconAdd from '../assets/add.svg';

const Log = () => {
  const { logId } = useParams();

  const log = useSelector((state: AppState) => logsSelectors.getById(state.logs, logId));

  const medium = useSelector((state: AppState) => mediaSelectors.getQuery(state.media, 'medium'));

  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (update: LogSubmitType) => {
    setLoading(true);

    const token = await getAccessTokenSilently();

    dispatch(logsUpdate(token, { ...update, logId }, () => setLoading(false)));
  };

  if (medium) {
    return isEmpty(log)
      ? <EmptyStateNotFound />
      : <LogForm log={log} onSubmit={onSubmit} loading={loading} />;
  }

  return (
    <section className="page log">
      <Header
        leftButton={(
          <Link to="/">
            <img src={iconArrowBack} alt="back" className="icon" />
          </Link>
        )}
        rightButton={(
          !isEmpty(log)
            ? (
              <Link to="/add-log">
                <img src={iconAdd} alt="add" className="icon" />
              </Link>
            )
            : null
        )}
      />
      {
        isEmpty(log)
          ? <EmptyStateNotFound />
          : <LogForm log={log} onSubmit={onSubmit} loading={loading} />
      }
    </section>
  );
};

export default Log;
