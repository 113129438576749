const prefix = 'PLANS';

const ADD = `${prefix}/ADD`;
const UPDATE = `${prefix}/UPDATE`;
const REMOVE = `${prefix}/REMOVE`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  UPDATE,
  REMOVE,
  CLEAR,
};
